import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Alert,
    Button,
    TextField,
    useTheme,
    useMediaQuery, Snackbar
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import {useGetActiveDisclosureQuery, useGetAllDisclosuresQuery, useUpsertDisclosureMutation} from '../../reducers/enrollmentApiSlice';
import LoadingSpinner from "../LoadingSpinner";
import useAlert from "../../hooks/useAlert";
import DisclosureList from "./DisclosureList";

const EditEmployerDisclosure = ({ selectedEmployer, userEmail }) => {
    const { translate } = useCustomTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {alert, setAlert, handleCloseAlert} = useAlert();
    const [currentDisclosureText, setCurrentDisclosureText] = useState('');

    const [expanded, setExpanded] = useState(false);
    const [upsertDisclosure, { isLoading: isUpdating, isError: isUpdateError, error: updateError }] = useUpsertDisclosureMutation();

    const { data: activeDisclosure, isLoading: activeDisclosureIsLoading, isError: activeDisclosureIsError, error: activeDisclosureError } = useGetActiveDisclosureQuery(
        { employerCode: selectedEmployer?.employerCode },
        { skip: !selectedEmployer?.employerCode }
    );

    const { data: allDisclosures, isLoading: allDisclosuresIsLoading, isError: allDisclosuresIsError, error: allDisclosuresError, refetch: refetchAllDisclosures, isFetching: isFetchingAllDisclosures } = useGetAllDisclosuresQuery(
        { employerCode: selectedEmployer?.employerCode },
        { skip: !selectedEmployer?.employerCode }
    );

    useEffect(() => {
        if (activeDisclosure != null && !activeDisclosureIsLoading) {
            setCurrentDisclosureText(activeDisclosure?.data?.disclosureText ?? '');
        }
    }, [activeDisclosure, activeDisclosureIsLoading, activeDisclosureIsError]);

    const handleSaveChanges = async () => {

        try {
            await upsertDisclosure({
                disclosureText: currentDisclosureText,
                employerCode: selectedEmployer.employerCode,
                changedBy: userEmail
            }).unwrap();
            setAlert({
                open: true,
                severity: 'success',
                message: translate('Saved!')
            });
            await refetchAllDisclosures();
        } catch(error) {
            console.error('Failed to save the disclosure:', error);
            setAlert({
                open: true,
                severity: 'error',
                message: translate('Failed to save the disclosure.')
            });
        }

    };

    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    };

    const isLoading = activeDisclosureIsLoading || allDisclosuresIsLoading;
    const isError = activeDisclosureIsError || allDisclosuresIsError;

    return (
        <>
            <Accordion expanded={expanded} onChange={handleAccordionChange} sx={{ mt: 4 }}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="employer-disclosure-content"
                    id="employer-disclosure-header"
                >
                    <Typography variant={isMobile ? "subtitle1" : "h6"}>
                        {translate('Employer Disclosure')}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
                    {isLoading && (
                        <Box display="flex" justifyContent="center" mt={4}>
                            <LoadingSpinner />
                        </Box>
                    )}
                    {isError && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            {translate('Error loading disclosure data.')}
                        </Alert>
                    )}

                    { !isLoading && !isError ? (<>
                    <Snackbar
                        open={alert.open}
                        autoHideDuration={6000}
                        onClose={() => {setAlert({ open: false, severity: '', message: '' });}}
                    >
                        <Alert onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>
                            {translate(alert.message)}
                        </Alert>
                    </Snackbar>

                    <TextField
                        margin="normal"
                        fullWidth
                        sx={{
                            padding: 0
                        }}
                        multiline
                        rows={15}
                        id="current-disclosure"
                        label={translate('Current Disclosure')}
                        name="current-disclosure"
                        value={currentDisclosureText}
                        onChange={(e) => {setCurrentDisclosureText(e.target.value)}}
                    />

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                        disabled={isUpdating}
                        onClick={() => { handleSaveChanges(); }}
                    >
                        {isUpdating ? translate('Saving...') : translate('Save Changes')}
                    </Button>

                    <Typography variant='h2' sx={{ mt: 4 }}>{translate('History')}</Typography>

                    <DisclosureList
                        allDisclosures={allDisclosures}
                        employerCode={selectedEmployer?.employerCode}
                        userEmail={userEmail}
                        refetch={refetchAllDisclosures}
                        isRefetching={isFetchingAllDisclosures}
                    />
                    </>) : null }

                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default EditEmployerDisclosure;