import { useState, useEffect, useCallback } from 'react';
import {
    useGetEmployerAdminRatesByPlanIdQuery,
    useGetCarrierRatesByPlanIdQuery,
    useGetAllRateTypesQuery,
    useUpsertEmployerAdminRateMutation,
    useGetBenefitTypesQuery,
    useGetAllIndividualTypesQuery,
    useUpsertEmployerPlanOptionMutation,
} from '../reducers/enrollmentApiSlice';
import AuthTokenService from '../services/AuthTokenService';
import {
    handleRatesProcessing,
    transformCarrierRateToEmployerAdminRate,
} from "../utilities/employerAdminRateHelpers";
import {
    displayResultAlert,
    processRateUpserts,
    resetAlertAfterDelay,
} from "../utilities/empoyerAdminRateUpsertUtils";
import { v4 as uuidv4 } from 'uuid';
import {nowAsIsoStringWithoutTimezone} from "../utilities/formatDate";

const useEmployerAdminRates = (
    planId,
    initialRateType,
    initialBenefitType,
    employerCode,
    selectedContributionType
) => {
    const [rates, setRates] = useState([]);
    const [rateType, setRateType] = useState(initialRateType);
    const [benefitType, setBenefitType] = useState(initialBenefitType);
    const [alert, setAlert] = useState(null);
    const [isUsingCarrierRates, setIsUsingCarrierRates] = useState(false);

    const { user: userEmail } = AuthTokenService.getAuthInfo();


    // API queries
    const {
        data: employerAdminRatesData,
        error: employerAdminRatesError,
        isLoading: employerAdminRatesLoading,
        refetch: refetchEmployerAdminRates,
    } = useGetEmployerAdminRatesByPlanIdQuery({ planId, employerCode, email: userEmail || '' });

    const {
        data: carrierRatesData,
        error: carrierRatesError,
        isLoading: carrierRatesLoading,
        refetch: refetchCarrierRates,
    } = useGetCarrierRatesByPlanIdQuery({ planId });
    const {
        data: rateTypesData,
        error: rateTypesError,
        isLoading: rateTypesLoading,
    } = useGetAllRateTypesQuery();
    const {
        data: benefitTypesData,
        error: benefitTypesError,
        isLoading: benefitTypesLoading,
    } = useGetBenefitTypesQuery();
    const {
        data: individualTypesData,
        error: individualTypesError,
        isLoading: individualTypesLoading,
    } = useGetAllIndividualTypesQuery();

    const [upsertEmployerAdminRate, { isLoading: isUpsertingRate }] =
        useUpsertEmployerAdminRateMutation();
    const [upsertEmployerPlanOption, { isLoading: isUpsertingPlanOption }] =
        useUpsertEmployerPlanOptionMutation();

    // Verbose logging for API data and errors
    useEffect(() => {
        console.log('API data and errors in useEmployerAdminRates:', {
            employerAdminRatesData,
            employerAdminRatesError,
            carrierRatesData,
            carrierRatesError,
            rateTypesData,
            rateTypesError,
            benefitTypesData,
            benefitTypesError,
            individualTypesData,
            individualTypesError,
        });
    }, [
        employerAdminRatesData,
        employerAdminRatesError,
        carrierRatesData,
        carrierRatesError,
        rateTypesData,
        rateTypesError,
        benefitTypesData,
        benefitTypesError,
        individualTypesData,
        individualTypesError,
    ]);

    // Process employer-specific rates and fetch carrier rates only if necessary
    useEffect(() => {
        if (employerAdminRatesData?.length > 0) {
            setIsUsingCarrierRates(false); // We are using employer-specific rates
            handleRatesProcessing(
                employerAdminRatesData,
                rateType,
                benefitType,
                setRates
            );
        } else if (
            !employerAdminRatesLoading &&
            employerAdminRatesData?.length === 0
        ) {
            setIsUsingCarrierRates(true); // Set flag to indicate we're using carrier rates
            refetchCarrierRates();
        }
    }, [
        employerAdminRatesData,
        employerAdminRatesLoading,
        rateType,
        benefitType,
        refetchCarrierRates,
    ]);

    // Handle carrier rates once fetched
    useEffect(() => {
        if (isUsingCarrierRates && carrierRatesData?.length > 0) {
            const transformedRates = carrierRatesData.map((rate) =>
                transformCarrierRateToEmployerAdminRate(
                    rate,
                    selectedContributionType,
                    employerCode
                )
            );
            handleRatesProcessing(
                transformedRates,
                rateType,
                benefitType,
                setRates
            );
        }
    }, [
        carrierRatesData,
        isUsingCarrierRates,
        rateType,
        benefitType,
        selectedContributionType,
        employerCode,
    ]);

    // Handle adding a new rate
    const handleAddRate = useCallback(
        (
            planId,
            carrierId,
            rateTypeId,
            employerCode,
            contributionTypeId
        ) => {
            const rateTypeObj = rateTypesData?.find(
                (type) => type.rateTypeId === rateTypeId
            );
            const benefitTypeObj = benefitTypesData?.find(
                (type) => type.typeName === benefitType
            );

            if (!rateTypeObj || !benefitTypeObj) {
                console.error('Rate or Benefit type not found', {
                    rateTypeObj,
                    benefitTypeObj,
                });
                return;
            }

            const newRate = {
                rateId: '0', // Mark as a new rate
                carrierId,
                rate: 0,
                smokingStatus: false,
                effectiveDate: nowAsIsoStringWithoutTimezone(),
                expirationDate: '',
                planId,
                coverageTier: 'Employee',
                rateTypeId: rateTypeObj.rateTypeId,
                rateTypeName: rateTypeObj.rateTypeName,
                customRateString: '',
                ageBand: '',
                email: userEmail,
                isActive: true,
                benefitType: benefitTypeObj,
                employerCode: employerCode,
                role: 'employer-admin',
                brokerRateId: null,
                basedOnBrokerRate: false,
                agencyRateId: null,
                basedOnAgencyRate: false,
                carrierRateId: null, // No carrier rate association for new employer-specific rates
                basedOnCarrierRate: false,
                contributionTypeId: contributionTypeId,
                contributionValue: 0,
                acaAffordability: false,
                tempId: `temp-${uuidv4()}`, // Temporary ID for newly added rates
            };

            setRates((prevRates) => [...prevRates, newRate]);
        },
        [rateTypesData, benefitType, benefitTypesData]
    );

    // Handle input changes for rates
    const handleInputChange = useCallback((id, field, value) => {
        setRates((prevRates) =>
            prevRates.map((rate) => {
                if (rate.tempId === id || rate.rateId === id) {
                    let updatedRate = { ...rate };

                    // Check and update specific fields
                    if (
                        [
                            'rate',
                            'contributionValue',
                            'eoiMax',
                            'min',
                            'max',
                            'increments',
                        ].includes(field)
                    ) {
                        updatedRate[field] = parseFloat(value) || 0;
                    } else if (field === 'coverageTier') {
                        updatedRate.coverageTier = value; // Ensure coverageTier is updated
                    } else {
                        // Update other fields dynamically
                        updatedRate[field] = value;
                    }

                    updatedRate.isModified = true; // Mark rate as modified
                    return updatedRate;
                }
                return rate;
            })
        );
    }, []);

    // Handle saving rates
    const handleSaveRates = useCallback(async () => {
        const { successCount, failureCount, upsertedRates } =
            await processRateUpserts(
                rates,
                selectedContributionType,
                upsertEmployerAdminRate
            );

        if (successCount > 0) {
            await refetchEmployerAdminRates();

            let planOptionSuccessCount = 0;
            let planOptionFailureCount = 0;

            for (const rate of upsertedRates) {
                const employerPlanOption = {
                    planId: parseInt(planId),
                    carrierRateId: rate.carrierRateId || 0,
                    agencyRateId: rate.agencyRateId || 0,
                    brokerRateId: rate.brokerRateId || 0,
                    employerAdminRateId: parseInt(rate.rateId),
                    employerCode: employerCode,
                    isActive: true,
                };

                try {
                    await upsertEmployerPlanOption(employerPlanOption).unwrap();
                    console.log(
                        'Employer plan option upserted successfully:',
                        employerPlanOption
                    );
                    planOptionSuccessCount++;
                } catch (error) {
                    console.error(
                        'Failed to upsert employer plan option:',
                        error
                    );
                    planOptionFailureCount++;
                }
            }

            displayResultAlert(successCount, failureCount, setAlert);
            if (planOptionSuccessCount > 0) {
                setAlert((prevAlert) => ({
                    ...prevAlert,
                    message: `${prevAlert.message} ${planOptionSuccessCount} plan option(s) associated successfully.`,
                }));
            }
            if (planOptionFailureCount > 0) {
                setAlert((prevAlert) => ({
                    ...prevAlert,
                    type: 'warning',
                    message: `${prevAlert.message} Failed to associate ${planOptionFailureCount} plan option(s).`,
                }));
            }
        } else {
            displayResultAlert(successCount, failureCount, setAlert);
        }

        resetAlertAfterDelay(setAlert);
    }, [
        rates,
        upsertEmployerAdminRate,
        refetchEmployerAdminRates,
        selectedContributionType,
        planId,
        employerCode,
        upsertEmployerPlanOption,
    ]);

    // Handle coverage tier change
    const handleCoverageTierChange = useCallback((id, value) => {
        setRates((prevRates) =>
            prevRates.map((rate) => {
                if (rate.tempId === id) {
                    return { ...rate, coverageTier: value };
                }
                return rate;
            })
        );
        setIsUsingCarrierRates(false);
    }, []);

    // Change rate type and benefit type
    const changeRateType = useCallback(
        (newRateType, newBenefitType) => {
            setRateType(newRateType);
            setBenefitType(newBenefitType);

            // Re-process rates when rate type changes
            if (employerAdminRatesData?.length > 0) {
                handleRatesProcessing(
                    employerAdminRatesData,
                    newRateType,
                    newBenefitType,
                    setRates
                );
            } else if (isUsingCarrierRates && carrierRatesData?.length > 0) {
                const transformedRates = carrierRatesData.map((rate) =>
                    transformCarrierRateToEmployerAdminRate(
                        rate,
                        selectedContributionType,
                        employerCode
                    )
                );
                handleRatesProcessing(
                    transformedRates,
                    newRateType,
                    newBenefitType,
                    setRates
                );
            }
        },
        [
            employerAdminRatesData,
            isUsingCarrierRates,
            carrierRatesData,
            selectedContributionType,
            employerCode,
        ]
    );

    // Clear unsaved rates
    const clearUnsavedRates = useCallback(() => {
        setRates((prevRates) => prevRates.filter((rate) => !rate.tempId));
    }, []);

    // Final verbose log of returned values
    useEffect(() => {
        console.log('Final state in useEmployerAdminRates:', {
            ratesCount: rates.length,
            rateType,
            benefitType,
            rateTypesDataAvailable: !!rateTypesData,
            benefitTypesDataAvailable: !!benefitTypesData,
            individualTypesDataAvailable: !!individualTypesData,
            isLoading:
                employerAdminRatesLoading ||
                carrierRatesLoading ||
                rateTypesLoading ||
                benefitTypesLoading ||
                individualTypesLoading,
            isUpsertingRate,
            errorPresent: !!(
                employerAdminRatesError ||
                carrierRatesError ||
                rateTypesError ||
                benefitTypesError ||
                individualTypesError
            ),
            alertPresent: !!alert,
            isUsingCarrierRates, // Indicating if carrier rates are being displayed
        });
    }, [
        rates,
        rateType,
        benefitType,
        rateTypesData,
        benefitTypesData,
        individualTypesData,
        employerAdminRatesLoading,
        carrierRatesLoading,
        rateTypesLoading,
        benefitTypesLoading,
        individualTypesLoading,
        isUpsertingRate,
        employerAdminRatesError,
        carrierRatesError,
        rateTypesError,
        benefitTypesError,
        individualTypesError,
        alert,
        isUsingCarrierRates,
    ]);

    return {
        rates,
        rateType,
        benefitType,
        rateTypesData,
        benefitTypesData,
        individualTypesData,
        isLoading:
            employerAdminRatesLoading ||
            carrierRatesLoading ||
            rateTypesLoading ||
            benefitTypesLoading ||
            individualTypesLoading,
        isUpsertingRate,
        error:
            employerAdminRatesError ||
            carrierRatesError ||
            rateTypesError ||
            benefitTypesError ||
            individualTypesError,
        alert,
        isUsingCarrierRates, // Expose the carrier rates flag
        handleInputChange,
        handleCoverageTierChange,
        handleAddRate,
        handleSaveRates,
        changeRateType, // Ensure this function is returned
        clearUnsavedRates, // Ensure this function is returned
        refetchEmployerAdminRates,
        refetchCarrierRates,
    };
};

export default useEmployerAdminRates;
