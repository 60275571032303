// ICHRAPlanDetailsModalHSA.js
import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import QuoteCardHSA from './QuoteCardHSA';

const ICHRAPlanDetailsModalHSA = ({ userEmail, open, onClose, quote, onSelectQuote}) => {
    if (!quote) return null;

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="plan-details-modal"
            aria-describedby="plan-details-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 'min(90%, 400px)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <Typography id="plan-details-modal" variant="h6" component="h2" gutterBottom>
                    Plan Details
                </Typography>
                <QuoteCardHSA userEmail={userEmail} quote={quote} onSelect={onSelectQuote} />
                <Button onClick={onClose} sx={{ mt: 2 }}>Close</Button>
            </Box>
        </Modal>
    );
};

export default ICHRAPlanDetailsModalHSA;