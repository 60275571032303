// piedmontConfig.js

const piedmontConfig = {
    NODE_ENV: 'development',
    REACT_APP_PIEDMONT_API_KEY: '2adbe507ac395e8af77a9d2707a80c52',
    REACT_APP_PIEDMONT_GROUP_NUMBER: 'GWT555',
    REACT_APP_PIEDMONT_AGENT_WRITING_NUMBER: 'GW123',
    REACT_APP_PIEDMONT_AGENT_USERNAME: 'gowell@gowell.com',
    REACT_APP_PIEDMONT_AGENT_PASSWORD: 'GoWell123',
};

export default piedmontConfig;
