export const submitEbToolkitEnrollmentObject = async (enrollWithDependentsMutation, enrollmentObject, changedBy, signatureId = null) => {
    console.log("Submitting the Ichra plan.");

    if (enrollmentObject == null) {
        console.log("Enrollment Object not found.");
        return;
    }

    try {
        let dto = enrollmentObject;
        dto.employeeBenefitEnrollment = {
            ...dto.employeeBenefitEnrollment,
            signatureId: signatureId,
            changedBy: changedBy, // Add the changedBy field here
        };
        await enrollWithDependentsMutation(dto).unwrap();
    }  catch (e) {
        console.log("Failed to submit Ichra enrollment.");
    }
}