// ReportingPage.jsx
import React, { useState } from 'react';
import { Box, Typography, Button, CircularProgress, useTheme } from '@mui/material';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import AuthTokenService from '../../services/AuthTokenService';
import YearSelector from './YearSelector';
import EmployerSelector from './EmployerSelector';
import CensusDataDownloader from '../Census/CensusDataDownloader';
import ReportGenerator from './ReportGenerator';

const ReportingPage = () => {
    const className = 'ReportingPage';
    const theme = useTheme();
    const { translate } = useCustomTranslation();
    const { user } = AuthTokenService.getAuthInfo();

    const currentYear = new Date().getFullYear();
    const [selectedEmployers, setSelectedEmployers] = useState([]);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [isLoading, setIsLoading] = useState(false);

    console.log(`[${className}] Component initialized with currentYear: ${currentYear}`);

    const handleEmployerSelect = (employers) => {
        console.log(
            `[${className}] Selected employers updated: ${JSON.stringify(employers, null, 2)}`
        );

        // Preserve original logic but ensure we only store employerCode strings
        const validEmployerCodes = employers
            .map((emp) => {
                if (emp && typeof emp.employerCode === 'string' && emp.employerCode.trim().length > 0) {
                    return emp.employerCode.trim();
                }
                console.warn(`[${className}] Invalid employer entry encountered: ${JSON.stringify(emp)}`);
                return null;
            })
            .filter((code) => code !== null);

        setSelectedEmployers(validEmployerCodes);
    };

    const handleYearChange = (year) => {
        console.log(`[${className}] Selected year changed to: ${year}`);
        setSelectedYear(year);
    };

    // -----------------------------------------
    // 1) Helper: Download CSV
    // -----------------------------------------
    const downloadCsv = (base64Csv, employerCode, reportType) => {
        try {
            console.log(
                `[${className}] Decoding CSV for employerCode: ${employerCode}, reportType: ${reportType}`
            );
            const decodedCsv = atob(base64Csv);
            const blob = new Blob([decodedCsv], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${reportType}_Report_${employerCode}_${selectedYear}.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            console.log(`[${className}] CSV downloaded for employerCode: ${employerCode}, reportType: ${reportType}`);
        } catch (error) {
            console.error(
                `[${className}] Error decoding CSV for employerCode: ${employerCode}, reportType: ${reportType}`,
                error
            );
        }
    };

    // -----------------------------------------
    // 2) Generate 1095 for each selected employer
    // Endpoint: GET https://yolo-insure.com/api/1095/by-year-and-employer?year=YYYY&employerCode=CODE
    // Returns: Raw Base64 in response body
    // -----------------------------------------
    const handleGenerate1095Reports = async () => {
        console.log(`[${className}] Generating 1095 Reports for: ${JSON.stringify(selectedEmployers)}`);
        setIsLoading(true);

        for (const employerCode of selectedEmployers) {
            try {
                const url = `https://yolo-insure.com/api/1095/by-year-and-employer?year=${selectedYear}&employerCode=${employerCode}`;
                console.log(`[${className}] Fetching 1095 from: ${url}`);
                const resp = await fetch(url);

                if (!resp.ok) {
                    throw new Error(`HTTP error! Status: ${resp.status}`);
                }

                // The 1095 endpoint returns raw Base64 string in the response body
                const base64Data = await resp.text();
                downloadCsv(base64Data, employerCode, '1095');
            } catch (error) {
                console.error(`[${className}] Error generating 1095 for employer: ${employerCode}`, error);
            }
        }

        setIsLoading(false);
    };

    // -----------------------------------------
    // 3) Generate Payroll for each selected employer
    // Endpoint: GET https://yolo-insure.com/api/payroll/base64?year=YYYY&employerCode=CODE
    // Returns JSON: { "data": "base64", "success": true, "message": null }
    // -----------------------------------------
    const handleGeneratePayrollReports = async () => {
        console.log(`[${className}] Generating Payroll Reports for: ${JSON.stringify(selectedEmployers)}`);
        setIsLoading(true);

        for (const employerCode of selectedEmployers) {
            try {
                const url = `https://yolo-insure.com/api/payroll/base64?year=${selectedYear}&employerCode=${employerCode}`;
                console.log(`[${className}] Fetching Payroll from: ${url}`);
                const resp = await fetch(url);

                if (!resp.ok) {
                    throw new Error(`HTTP error! Status: ${resp.status}`);
                }

                // The Payroll endpoint returns JSON with base64 in "data"
                const data = await resp.json();

                if (!data.success || !data.data) {
                    throw new Error(`Invalid response. success: ${data.success}, data: ${data.data}`);
                }

                downloadCsv(data.data, employerCode, 'Payroll');
            } catch (error) {
                console.error(`[${className}] Error generating Payroll for employer: ${employerCode}`, error);
            }
        }

        setIsLoading(false);
    };

    // -----------------------------------------
    // 4) Download All Reports button:
    //    - Generate 1095, then Payroll (sequentially)
    // -----------------------------------------
    const handleDownloadAllReports = async () => {
        console.log(`[${className}] Download all reports initiated`);
        setIsLoading(true);

        try {
            await handleGenerate1095Reports();
            await handleGeneratePayrollReports();
        } catch (error) {
            console.error(`[${className}] Error during "Download All Reports"`, error);
        }

        setIsLoading(false);
        console.log(`[${className}] Download all reports completed`);
    };

    return (
        <Box
            sx={{
                p: 4,
                backgroundColor: theme.palette.background.default,
                borderRadius: 2,
                boxShadow: theme.shadows[2],
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 4,
            }}
            role="main"
            aria-labelledby="reporting-page-title"
        >
            <Typography
                id="reporting-page-title"
                variant="h4"
                sx={{ color: theme.palette.primary.main, fontWeight: 'bold', textAlign: 'center' }}
            >
                {translate('Reporting')}
            </Typography>

            <Typography
                variant="subtitle1"
                sx={{ color: theme.palette.text.secondary, textAlign: 'center' }}
            >
                {translate('Select employers, choose a year, and generate reports.')}
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: 3,
                    width: '100%',
                    maxWidth: 700,
                    justifyContent: 'space-between',
                }}
            >
                <YearSelector
                    selectedYear={selectedYear}
                    onYearChange={handleYearChange}
                    aria-label={translate('Year Selector')}
                />

                <EmployerSelector
                    email={user}
                    onEmployerSelect={handleEmployerSelect}
                    aria-label={translate('Employer Selector')}
                />
            </Box>

            <Box
                sx={{
                    mt: 4,
                    width: '100%',
                    maxWidth: 800,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                }}
            >
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
                    {translate('Generate Reports')}
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        gap: 2,
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    {/* Updated 1095 Button */}
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={selectedEmployers.length === 0 || isLoading}
                        sx={{ minWidth: 200, textAlign: 'center' }}
                        aria-label={translate('Generate 1095 Report')}
                        onClick={handleGenerate1095Reports}
                    >
                        {isLoading ? <CircularProgress size={24} color="inherit" /> : translate('Generate 1095 Report')}
                    </Button>

                    {/* Updated Payroll Button */}
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={selectedEmployers.length === 0 || isLoading}
                        sx={{ minWidth: 200, textAlign: 'center' }}
                        aria-label={translate('Generate Payroll Report')}
                        onClick={handleGeneratePayrollReports}
                    >
                        {isLoading ? <CircularProgress size={24} color="inherit" /> : translate('Generate Payroll Report')}
                    </Button>

                    {/* "Download All Reports" triggers both 1095 and Payroll */}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDownloadAllReports}
                        disabled={isLoading || selectedEmployers.length === 0}
                        sx={{ minWidth: 200, textAlign: 'center' }}
                        aria-label={translate('Download All Reports')}
                    >
                        {isLoading ? <CircularProgress size={24} color="inherit" /> : translate('Download All Reports')}
                    </Button>
                </Box>
            </Box>

            <Box
                sx={{
                    mt: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                    width: '100%',
                }}
            >
                {selectedEmployers.map((employerCode, index) => (
                    <CensusDataDownloader
                        key={index}
                        employerCode={employerCode}
                        aria-label={translate(`Download Census Data for ${employerCode}`)}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default ReportingPage;
