import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import {
    CircularProgress,
    Typography,
    Container,
    Checkbox,
    IconButton,
    TextField,
    Box,
    Snackbar,
    Alert,
    Button,
    InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
    useGetEmployeesByEmployerCodeQuery,
    useGetAllEmployeeStatusesQuery,
    useGetMaritalStatusesQuery,
    useGetEmployerAdminsByCodeQuery,
    useCreateEmployerAdminMutation,
    useRemoveEmployerAdminMutation,
    useTerminateEmployeeMutation
} from '../../reducers/enrollmentApiSlice';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import './EmployeeDataGrid.css';

const EmployeeDataGrid = ({ employerCode, currentUserEmail, onSuccess }) => {
    const { translate } = useCustomTranslation();
    const { data: employeeData, error, isLoading: isEmployeesLoading, refetch } = useGetEmployeesByEmployerCodeQuery(employerCode, {
        skip: !employerCode,
    });

    const { data: employeeStatuses = [], isLoading: isEmployeeStatusesLoading } = useGetAllEmployeeStatusesQuery();
    const { data: maritalStatuses = [], isLoading: isMaritalStatusesLoading } = useGetMaritalStatusesQuery();
    const { data: adminResponse, isLoading: isAdminsLoading } = useGetEmployerAdminsByCodeQuery(employerCode, {
        skip: !employerCode,
    });

    const adminData = adminResponse?.data || [];
    const [createEmployerAdmin, { isLoading: isCreatingAdmin }] = useCreateEmployerAdminMutation();
    const [removeEmployerAdmin, { isLoading: isRemovingAdmin }] = useRemoveEmployerAdminMutation();
    const [terminateEmployee, { isLoading: isTerminatingEmployee }] = useTerminateEmployeeMutation();
    const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (employeeData) {
            const uniqueAdminEmails = [...new Set(adminData.map(admin => admin.email))];
            const updatedRows = employeeData.map(emp => ({
                id: emp.employeeId,
                ...emp.personDetails,
                ...emp,
                isAdmin: uniqueAdminEmails.includes(emp.email),
                isCurrentUser: emp.email === currentUserEmail,
                isTerminated: emp.employeeStatusId === 10,
            }));
            setRows(updatedRows);
            setFilteredRows(updatedRows);
        }
    }, [employeeData, adminData, currentUserEmail]);

    const handleSearchChange = (event) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);
        const filtered = rows.filter(
            (row) =>
                row.firstName.toLowerCase().includes(term) ||
                row.lastName.toLowerCase().includes(term) ||
                row.email.toLowerCase().includes(term)
        );
        setFilteredRows(filtered);
    };

    const handleAlertClose = () => {
        setAlert({ ...alert, open: false });
    };

    const handleTerminateEmployee = async (employee) => {
        if (window.confirm(translate('Are you sure you want to terminate this employee?'))) {
            try {
                await terminateEmployee({
                    changedBy: currentUserEmail,
                    employeeToTerminateEmail: employee.email,
                    employerCode: employerCode
                }).unwrap();
                setAlert({ open: true, message: 'Employee terminated successfully.', severity: 'success' });

                setRows(prevRows =>
                    prevRows.map(row =>
                        row.id === employee.employeeId ? { ...row, isTerminated: true } : row
                    )
                );

                setFilteredRows(prevRows =>
                    prevRows.map(row =>
                        row.id === employee.employeeId ? { ...row, isTerminated: true } : row
                    )
                );

                onSuccess();
            } catch (error) {
                console.error('Failed to terminate the employee:', error);
                setAlert({ open: true, message: 'Failed to terminate the employee.', severity: 'error' });
            }
        }
    };

    const handleAdminCheckboxChange = async (employee, event) => {
        const isChecked = event.target.checked;

        if (isChecked) {
            try {
                await createEmployerAdmin({ email: employee.email, employerCode }).unwrap();
                setAlert({ open: true, message: 'Employer admin created successfully.', severity: 'success' });

                setRows(prevRows =>
                    prevRows.map(row =>
                        row.id === employee.employeeId ? { ...row, isAdmin: true } : row
                    )
                );

                setFilteredRows(prevRows =>
                    prevRows.map(row =>
                        row.id === employee.employeeId ? { ...row, isAdmin: true } : row
                    )
                );

                onSuccess();
            } catch (error) {
                console.error('Failed to create employer admin:', error);
                setAlert({ open: true, message: 'Failed to create employer admin.', severity: 'error' });
            }
        } else {
            try {
                await removeEmployerAdmin({ email: employee.email, employerCode }).unwrap();
                setAlert({ open: true, message: 'Employer admin removed successfully.', severity: 'success' });

                setRows(prevRows =>
                    prevRows.map(row =>
                        row.id === employee.employeeId ? { ...row, isAdmin: false } : row
                    )
                );

                setFilteredRows(prevRows =>
                    prevRows.map(row =>
                        row.id === employee.employeeId ? { ...row, isAdmin: false } : row
                    )
                );

                onSuccess();
            } catch (error) {
                console.error('Failed to remove employer admin:', error);
                setAlert({ open: true, message: 'Failed to remove employer admin.', severity: 'error' });
            }
        }
    };

    const handleBenefitEnrollments = (employee) => {
        if (employee && employee.email) {
            navigate(`/benefit-enrollment/${employee.email}`);
        } else {
            console.error("Employee email is undefined, cannot navigate.");
        }
    };

    const handleEditEmployee = (employee) => {
        navigate(`/edit-account-information/${employee.email}`, { state: { selectedEmployee: employee } });
    };

    const columns = [
        { field: 'employeeId', headerName: translate('ID'), width: 90 },
        { field: 'firstName', headerName: translate('First name'), width: 150 },
        { field: 'lastName', headerName: translate('Last name'), width: 150 },
        { field: 'email', headerName: translate('Email'), width: 200 },
        {
            field: 'admin',
            headerName: translate('Admin'),
            width: 150,
            renderCell: (params) => (
                <Checkbox
                    checked={params.row.isAdmin}
                    onChange={(event) => handleAdminCheckboxChange(params.row, event)}
                    aria-label={translate('Make Admin')}
                />
            ),
        },
        {
            field: 'benefits',
            headerName: translate('Benefit Enrollments'),
            width: 200,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleBenefitEnrollments(params.row)}
                    aria-label={translate('View Benefits')}
                >
                    {translate('View Benefits')}
                </Button>
            ),
        },
        {
            field: 'edit',
            headerName: translate('Edit Employee'),
            width: 150,
            renderCell: (params) => (
                <IconButton
                    color="primary"
                    onClick={() => handleEditEmployee(params.row)}
                    aria-label={translate('Edit Employee')}
                >
                    <EditIcon />
                </IconButton>
            ),
        },
        {
            field: 'terminate',
            headerName: translate('Terminate Employee'),
            width: 150,
            renderCell: (params) => (
                <IconButton
                    color="secondary"
                    onClick={() => handleTerminateEmployee(params.row)}
                    aria-label={translate('Terminate Employee')}
                    style={{ color: params.row.isTerminated ? 'lightgray' : 'red', opacity: params.row.isTerminated ? 0.8 : 1.0 }}
                    disabled={params.row.isTerminated}
                >
                    <DeleteIcon />
                </IconButton>
            ),
        },
    ];

    return (
        <Container>
            {(isEmployeesLoading || isEmployeeStatusesLoading || isMaritalStatusesLoading || isAdminsLoading || isCreatingAdmin || isRemovingAdmin || isTerminatingEmployee) && (
                <CircularProgress aria-label={translate('Loading')} />
            )}
            {error && <Alert severity="error" aria-label={translate('Error')}>{error.message}</Alert>}
            <Box mt={2} mb={2}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={translate('Search by email, first name, or last name')}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    aria-label={translate('Search Employees')}
                />
            </Box>
            <Box mt={2}>
                <DataGrid
                    rows={filteredRows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                    autoHeight
                    aria-label={translate('Employee Data Grid')}
                />
            </Box>
            <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={alert.severity} sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

EmployeeDataGrid.propTypes = {
    employerCode: PropTypes.string.isRequired,
    currentUserEmail: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default EmployeeDataGrid;