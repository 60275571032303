
export const accountPreferencesEndpoints = (builder) => ({
    addOrUpdatePreferences: builder.mutation({
        query: (request) => ({
            url: '/AccountPreferences/AddOrUpdatePreferences',
            method: 'Post',
            data: request,
        }),
    }),

    getPreferencesByEmail: builder.query({
        query: ({accountEmail}) => ({
            url: `/AccountPreferences/GetPreferencesByEmail?accountEmail=${encodeURIComponent(accountEmail)}`,
            method: 'Post',
            transformResponse: (response) => response ?? null
        }),
    }),
});
