export const reportingEndpoints = (builder) => ({
    get1095ReportByYearAndEmployerCode: builder.query({
        query: ({ year, employerCode }) => ({
            url: `/api/1095/by-year-and-employer?${new URLSearchParams({ year, employerCode }).toString()}`,
            method: 'GET',
        }),
        transformResponse: (response) => response ?? [],
    }),
    getPayrollReportBase64: builder.query({
        query: ({ year, employerCode = null }) => ({
            url: `/api/payroll/base64?${new URLSearchParams({ year, employerCode }).toString()}`,
            method: 'GET',
        }),
        transformResponse: (response) => response ?? null,
    }),
});
