import React, { useState, useEffect } from 'react';
import {Box, Container, Paper, useMediaQuery, useTheme} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import BenefitEnrollmentStatus from './BenefitEnrollmentStatus';
import EmploymentDetailsComponent from "../EmployementDetailsComponent/EmployementDetailsComponent";
import HappyMan2Image from "../../assets/images/HappyMan2.png";

import {
    startAccountPage,
    completeAccountPage,
    startEmploymentDetailsPage,
    completeEmploymentDetailsPage,
    startDependentPage,
    completeDependentPage,
    startAddressPage,
    completeAddressPage,
    startPlanSelectionPage,
    completePlanSelectionPage,
    startMedicalInfoPage,
    completeMedicalInfoPage,
    startReviewPage,
    completeReviewPage,
    startAgreeAndSignPage,
    completeAgreeAndSignPage
} from '../../reducers/benefitEnrollmentSlice';
import AccountInformation from "../AccountInformation/AccountInformation";
import DependentPage from "../AddDependents/DependentPage";
import AddAddressPage from "../AddAddress/AddAddressPage";
import PlanSelectionForm from "../PlanSelection";
import EnrollmentMedicalQuestionsPage from "../EnrollmentMedicalQuestionsPage/EnrollmentMedicalQuestionsPage";
import ReviewPage from "../ReviewBenefitPage/ReviewPage";
import AgreeAndSignPage from "../AgreeAndSign/AgreeAndSignPage";
import IchraWallet from "../ICHRA/IchraWallet";
import AuthTokenService from "../../services/AuthTokenService";

const EnrollmentPage = () => {
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [componentKey, setComponentKey] = useState(0);
    const [remountAccountDetails, setRemountAccountDetails] = useState(false);
    const dispatch = useDispatch();
    const state = useSelector((state) => state.benefitEnrollment);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { user: userEmail } = AuthTokenService.getAuthInfo();

    useEffect(() => {
        const startActions = [
            startAccountPage,
            startEmploymentDetailsPage,
            startDependentPage,
            startAddressPage,
            startPlanSelectionPage,
            startMedicalInfoPage,
            startReviewPage,
            startAgreeAndSignPage
        ];
        dispatch(startActions[currentStepIndex]());
    }, [currentStepIndex, dispatch]);

    const handleNavigateToAddressPage = () => {
        setCurrentStepIndex(3);
    };

    useEffect(() => {
        if (remountAccountDetails) {
            setRemountAccountDetails(false);
        }
    }, [remountAccountDetails]);

    const handleNextStep = () => {
        setLoading(true);
        dispatch(steps[currentStepIndex].completeAction());
        setTimeout(() => {
            setCurrentStepIndex((prevIndex) => {
                const nextIndex = prevIndex + 1;
                if (nextIndex < steps.length) {
                    setLoading(false);
                    setComponentKey((prevKey) => prevKey + 1);
                    return nextIndex;
                }
                setLoading(false);
                return prevIndex;
            });
        }, 500);
    };

    const handleNavigateToEmploymentDetailsPage = () => {
        setCurrentStepIndex(1);
    };

    const handleNavigateToDependentPage = () => {
        setCurrentStepIndex(2);
    };

    const handleBackStep = () => {
        setLoading(true);
        setCurrentStepIndex((prevIndex) => {
            const nextIndex = prevIndex - 1;
            setLoading(false);
            setComponentKey((prevKey) => prevKey + 1);
            return nextIndex >= 0 ? nextIndex : 0;
        });
    };

    const handleNavigateToPlanSelectionPage = () => {
        setCurrentStepIndex(4);
    };

    const handleStepClick = (index) => {
        if (index <= currentStepIndex) {
            setCurrentStepIndex(index);
            setComponentKey((prevKey) => prevKey + 1);
        }
    };

    const handleNavigateToAccountPage = () => {
        setCurrentStepIndex(0);
        setRemountAccountDetails(true);
    };

    const steps = [
        { component: AccountInformation, completeAction: completeAccountPage },
        { component: EmploymentDetailsComponent, completeAction: completeEmploymentDetailsPage },
        { component: DependentPage, completeAction: completeDependentPage },
        { component: AddAddressPage, completeAction: completeAddressPage },
        { component: PlanSelectionForm, completeAction: completePlanSelectionPage },
        { component: EnrollmentMedicalQuestionsPage, completeAction: completeMedicalInfoPage },
        { component: ReviewPage, completeAction: completeReviewPage },
        { component: AgreeAndSignPage, completeAction: completeAgreeAndSignPage },
    ];

    const CurrentComponent = steps[currentStepIndex].component;

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            backgroundImage: `url(${HappyMan2Image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            position: 'relative',
            zIndex: 0
        }}>
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                zIndex: 1
            }} />
            <Container component="main" maxWidth="lg" sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                mt: 4,
                zIndex: 2,
                position: 'relative',
                boxShadow: 'none',
                border: 'none',
                padding: isMobile ? '1rem' : '2rem'
            }}>
                <Box sx={{
                    flex: isMobile ? 'auto' : 1,
                    mb: isMobile ? 2 : 0
                }}>
                    <BenefitEnrollmentStatus
                        currentStepIndex={currentStepIndex}
                        onNext={handleNextStep}
                        onBack={handleBackStep}
                        onStepClick={handleStepClick}
                        loading={loading}
                    />
                    {/* HACK: Pass a boolean, so that we can detect if we are on the plan selection page.
                              If the cost of the selected Ichra plan exceeds the employer contribution amount, then we show a warning modal.
                              Something weird is going on with global state and it will show the prompt even after it was acknowledge/closed on other pages,
                              so for now we will workaround this by only showing it on the plan selection page.
                    */
                    }
                    {currentStepIndex > 0 ? <IchraWallet userEmail={userEmail} isOnPlanSelectionPage={currentStepIndex === 4} /> : null }
                </Box>
                <Box sx={{
                    flex: isMobile ? 'auto' : 2,
                    ml: isMobile ? 0 : 4
                }}>
                    <Paper sx={{
                        padding: isMobile ? '1rem' : '2rem',
                        backgroundColor: 'rgba(255, 255, 255, 0)',
                        backdropFilter: 'blur(5px)'
                    }}>
                        {remountAccountDetails ? (
                            <AccountInformation
                                key={Date.now()}
                                onNext={handleNextStep}
                                onNavigateToAccountPage={handleNavigateToAccountPage}
                            />
                        ) : (
                            <CurrentComponent
                                key={componentKey}
                                onNext={handleNextStep}
                                onNavigateToEmploymentDetailsPage={handleNavigateToEmploymentDetailsPage}
                                onNavigateToDependentPage={handleNavigateToDependentPage}
                                onNavigateToAccountPage={handleNavigateToAccountPage}
                                currentStepIndex={currentStepIndex}
                                onNavigateToAddressPage={handleNavigateToAddressPage}
                                onNavigateToPlanSelectionPage={handleNavigateToPlanSelectionPage}
                            />
                        )}
                    </Paper>
                </Box>
            </Container>
        </Box>
    );
};

export default EnrollmentPage;