import {useGetCostPerPayPeriodForEmployeeFromRateHSAQuery} from "../reducers/enrollmentApiSlice";
import {formatCurrency} from "../utilities/formatCurrency";
import {useEffect} from "react";

export const useGetCostPerPayPeriodHsa = (userEmail, quote) => {
    const { data: costPerPayPeriodData, isLoading: isCostPerPayPeriodDataLoading, isFetching, isError: isCostPerPayPeriodDataError, refetch, isUninitialized} = useGetCostPerPayPeriodForEmployeeFromRateHSAQuery({
        userEmail: userEmail,
        rate: +(quote?.censusTotal ?? 0)
    }, { skip: userEmail == null || quote == null});

    const costPerPayPeriod = costPerPayPeriodData?.data?.costPerPayPeriod ?? null;
    const annualCost = costPerPayPeriodData?.data?.annualCost ?? null;

    useEffect(() => {
        if (!isUninitialized) {
            refetch();
        }
    }, [quote]);

    if (isFetching || quote == null) {
        return {
            costPerPayPeriodFormatted: 'N/A',
            annualCostFormatted: 'N/A',
            costPerPayPeriod: null,
            annualCost: null,
            isCostPerPayPeriodDataLoading: isCostPerPayPeriodDataLoading,
            isCostPerPayPeriodDataError: isCostPerPayPeriodDataError,
        }
    }

    return {
        costPerPayPeriodFormatted: costPerPayPeriod == null ? 'N/A' : formatCurrency(costPerPayPeriodData.data.costPerPayPeriod.toFixed(2)),
        annualCostFormatted: annualCost == null ? 'N/A' : formatCurrency(costPerPayPeriodData.data.annualCost.toFixed(2)),
        costPerPayPeriod: costPerPayPeriod,
        annualCost: annualCost,
        isCostPerPayPeriodDataLoading: isCostPerPayPeriodDataLoading || isFetching,
        isCostPerPayPeriodDataError: isCostPerPayPeriodDataError,
    }
};