import React, { useState, useEffect } from 'react';
import {
    Container,
    Box,
    TextField,
    Button,
    Grid,
    Alert,
    FormControlLabel,
    Switch
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useUpsertCarrierMutation } from '../../reducers/enrollmentApiSlice';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import { logDebug } from '../../utilities/Logger';
import { setSelectedCarrier } from '../../reducers/createInsurancePlanSlice';
import AuthTokenService from "../../services/AuthTokenService";

const EditCarrierForm = () => {
    const dispatch = useDispatch();
    const { translate } = useCustomTranslation();
    const { user: userEmail } = AuthTokenService.getAuthInfo();

    const selectedCarrier = useSelector((state) => state.createInsurancePlan.selectedCarrier);

    const [formValues, setFormValues] = useState({
        carrierName: '',
        carrierCode: '',
        description: '',
        isActive: true,
        changedBy: userEmail || '' // Initialize changedBy with user email
    });

    const [upsertCarrier, { isLoading, isError, isSuccess }] = useUpsertCarrierMutation();
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);

    useEffect(() => {
      /*  logDebug('Selected carrier:', selectedCarrier);*/
        if (selectedCarrier) {
            const updatedValues = {
                carrierName: selectedCarrier.carrierName || '',
                carrierCode: selectedCarrier.carrierCode || '',
                description: selectedCarrier.description || '',
                isActive: selectedCarrier.isActive !== undefined ? selectedCarrier.isActive : true,
                changedBy: userEmail || '' // Set changedBy from user email
            };

            logDebug('Form values updated with selected carrier:', updatedValues);
            setFormValues(updatedValues);
        }
    }, [selectedCarrier, userEmail]);

    useEffect(() => {
/*
        logDebug('Upsert status - isSuccess:', isSuccess, 'isError:', isError);
*/
        if (isSuccess) {
            setShowSuccessAlert(true);
            dispatch(setSelectedCarrier(null));

            if (!formValues.isActive) {
                logDebug('Carrier is inactive, refreshing page...');
                setTimeout(() => {
                    window.location.reload();
                }, 1500); // Delay of 1.5 seconds before refreshing
            }
        } else if (isError) {
            setShowErrorAlert(true);
        }
    }, [isSuccess, isError, dispatch, formValues.isActive]);

    const handleChange = (event) => {
        const { name, value, checked } = event.target;
        const newValue = name === 'isActive' ? checked : value;

        const updatedValues = {
            ...formValues,
            [name]: newValue,
            changedBy: userEmail || '' // Persist changedBy with user email
        };

/*
        logDebug(`Form values updated on change - ${name}:`, updatedValues);
*/
        setFormValues(updatedValues);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
/*
        logDebug('Submitting form with values:', formValues);
*/

        if (!formValues.changedBy) {
            console.error('Error: changedBy is missing before submitting the form.');
            return;
        }

        try {
            await upsertCarrier(formValues).unwrap();
/*
            logDebug('Carrier upsert successful');
*/
        } catch (error) {
/*
            logDebug('Failed to upsert carrier:', error);
*/
            console.error('Failed to upsert carrier', error);
        }
    };

    return (
        <Container component="main" maxWidth="md">
            <Box sx={{ my: 4 }}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} md={8}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            {showSuccessAlert && (
                                <Alert severity="success" onClose={() => setShowSuccessAlert(false)}>
                                    {translate('Carrier saved successfully!')}
                                </Alert>
                            )}
                            {showErrorAlert && (
                                <Alert severity="error" onClose={() => setShowErrorAlert(false)}>
                                    {translate('Failed to save carrier')}
                                </Alert>
                            )}
                            <Box component="form" sx={{ mt: 1 }} onSubmit={handleSubmit}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="carrierName"
                                    label={translate('Carrier Name')}
                                    name="carrierName"
                                    value={formValues.carrierName}
                                    onChange={handleChange}
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="carrierCode"
                                    label={translate('Carrier Code')}
                                    name="carrierCode"
                                    value={formValues.carrierCode}
                                    onChange={handleChange}
                                    disabled={!!selectedCarrier?.carrierCode}
                                />
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="description"
                                    label={translate('Description')}
                                    name="description"
                                    value={formValues.description}
                                    onChange={handleChange}
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formValues.isActive}
                                            onChange={handleChange}
                                            name="isActive"
                                            color="primary"
                                        />
                                    }
                                    label={translate('Is Active')}
                                    sx={{ mt: 2, mb: 1 }}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    disabled={isLoading}
                                >
                                    {translate('Save')}
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default EditCarrierForm;
