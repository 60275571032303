import {useEffect, useState} from 'react';
import {
    useGetDependentsByAccountEmailQuery
} from '../reducers/enrollmentApiSlice';
import useGetEmployerCode from "./useGetEmployerCode";


const useFetchEnrollmentObjectInformationIchra = ({ userEmail, selectedPlan, selectedDependents, waiveData }) => {
    const [enrollmentObject, setEnrollmentObject] = useState(null);

    console.log('User email:', userEmail);
    console.log('Selected plan', selectedPlan);
    console.log('Selected dependents:', selectedDependents);

    const { employerCode, isLoading: isEmployerCodeLoading, isError: isEmployerCodeError } = useGetEmployerCode({ userEmail: userEmail });

    // Fetch dependents data
    const { data: dependentsData, isLoading: isDependentsDetailsLoading, isError: isDependentsDetailsError } = useGetDependentsByAccountEmailQuery({ email: userEmail }, {
        skip: !userEmail
    });

    useEffect(() => {
        if(isEmployerCodeLoading || isDependentsDetailsLoading || employerCode == null || dependentsData == null || selectedPlan == null) {
            return;
        }
        const currentDate = new Date().toISOString();
        const formattedDate = currentDate.split('T')[0];

        const isWaived = waiveData.shouldWaive;
        let dependentIds = selectedDependents;

        const coveredDependents = isWaived ? '' : dependentsData
            .filter(dep => ('' + dep.dependentId) in dependentIds)
            .map(dep => `${dep.person.firstName} ${dep.person.lastName}`)
            .join(', ');
        const coveredDependentsArray = isWaived ? [] : dependentsData.filter(dep => dependentIds.includes('' + dep.dependentId));

        let coveredIndividuals = 'Employee';  // Default
        const hasSpouse = coveredDependentsArray.some(dep => dep.relationshipType === 'Spouse/Domestic Partner');
        const childrenCount = coveredDependentsArray.filter(dep => dep.relationshipType === 'Child').length;

        if (hasSpouse && childrenCount === 0) {
            coveredIndividuals = 'Employee + Spouse';
        } else if (!hasSpouse && childrenCount === 1) {
            coveredIndividuals = 'Employee + Child';
        } else if (hasSpouse || childrenCount > 1) {
            coveredIndividuals = 'Family';
        }

        console.log(coveredDependentsArray, hasSpouse, childrenCount, dependentsData, dependentIds, coveredIndividuals);

        const formattedData = {
            // What we need:
            additionalInfo: JSON.stringify({
                type: 'ebtoolkit',
                plan: selectedPlan,
            }),
            offeringWaiver: isWaived,
            waiverReason: isWaived ? waiveData.reason : '',
            coveredIndividuals,
            beneficiary: '', // Local plans need this.
            coverageAmount: 0, // Local plans need this.
            planId: null, // Local plans need this.
            employeeBenefitEnrollment: {
                accountEmail: userEmail,
                employerCode,
                enrollmentStatus: 'pending',
                dependentIds: dependentIds.length > 0 ? dependentIds : null,
                // Not needed:
                offeringId: 0,
                id: 0,
            },
            benefitTypeId: 6, // If they waive the plan, then we need to keep this, so we know what benefit type they waived.

            // ------------------------------------------
            // Not needed / probably should be removed...
            offeringId: 0,
            ratePerThousand: 0, // The back-end uses the data in additionalInfo to calculate this on the back-end.
            effectiveDate: formattedDate,
            termDate: formattedDate,
            enrollmentTimestamp: currentDate,
            offeringName: selectedPlan?.planName ?? '',
            totalPremium: 0,
            employerContribution: 0,
            employeeContribution: 0,
            policyType: '',
            guaranteedIssue: true,
            cost: 0,
            insuranceCarrier: isWaived ? null : selectedPlan.issuerName,
        };
        console.log('Formatted enrollment data for eb toolkit:', formattedData);
        setEnrollmentObject(formattedData);
    }, [employerCode, dependentsData, selectedPlan, selectedDependents, waiveData]);

    const isLoading = isDependentsDetailsLoading || isEmployerCodeLoading;
    const isError = isDependentsDetailsError || isEmployerCodeError;

    return {
        enrollmentObject: enrollmentObject,
        isLoading: isLoading,
        isError: isError,
    };
};

export default useFetchEnrollmentObjectInformationIchra;
