import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    Box,
    Typography,
    Grid,
    Button,
    Paper,
    Alert,
    Container,
    CircularProgress,
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
    TextField, Accordion, AccordionSummary, AccordionDetails
} from '@mui/material';
import {
    useGetAllEmployerPlanSelectionsQuery,
    useGetDependentsByAccountEmailQuery,
    useEnrollWithDependentsMutation
} from '../../reducers/enrollmentApiSlice';
import useCustomTranslation from "../../hooks/useCustomTranslation";
import { addOrUpdateSelection } from '../../reducers/selectedPlanSlice';
import AuthTokenService from "../../services/AuthTokenService";
import EbIchraEnrollment from "../ICHRA/Ebtoolkit/EbIchraEnrollment";
import useFetchEnrollmentObjectInformationIchra from "../../hooks/useFetchEnrollmentObjectInformationIchra";
import {
    submitEbToolkitEnrollmentObject,
} from "../../utilities/ichra/ebtoolkit/submitEbtoolkitEnrollmentObject";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useFormatEnrollmentDataForLocalPlan from "../../hooks/useFormatEnrollmentDataForLocalPlan";

const EditUserEnrollment = ({ userEmail, employerCode, onEnrollmentComplete }) => {
    const dispatch = useDispatch();
    const { translate } = useCustomTranslation();
    const [selectedPlans, setSelectedPlans] = useState({});
    const [dependentsCoverage, setDependentsCoverage] = useState({});
    const [waiverReasons, setWaiverReasons] = useState({});
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertSeverity, setAlertSeverity] = useState('info');

    const { user: adminEmail } = AuthTokenService.getAuthInfo();
    const { data: employerPlans, isLoading: isPlansLoading } = useGetAllEmployerPlanSelectionsQuery({ employerCode });
    const { data: dependentsData, isLoading: isDependentsLoading } = useGetDependentsByAccountEmailQuery({ email: userEmail });
    const [enrollWithDependents, { isLoading: isEnrolling }] = useEnrollWithDependentsMutation();

    // Eb toolkit
    const {selectedPlanInfo: selectedPlanIchra, selectedDependents: selectedDependentsIchra, waive: waiveIchra} = useSelector(state => state.ebToolkit);
    const {enrollmentObject: enrollmentObjectIchra, isLoading: isLoadingIchra, isError: isErrorIchra} = useFetchEnrollmentObjectInformationIchra({
        userEmail: userEmail,
        selectedPlan: selectedPlanIchra,
        selectedDependents: selectedDependentsIchra,
        waiveData: waiveIchra
    })

    const formatLocalEnrollmentPlan = useFormatEnrollmentDataForLocalPlan(userEmail, employerCode, adminEmail, [userEmail, employerCode, adminEmail]);

    useEffect(() => {
        if (employerPlans && dependentsData && Object.keys(dependentsCoverage).length === 0) {
            const initialDependentsCoverage = {};
            employerPlans.plans.forEach(plan => {
                initialDependentsCoverage[plan.planId] = {};
                dependentsData.forEach(dependent => {
                    const dependentSSN = dependent.person?.socialSecurityNumber; // Access the correct path
                    if (dependentSSN) {
                        initialDependentsCoverage[plan.planId][dependentSSN] = false;
                    }
                });
            });
            setDependentsCoverage(initialDependentsCoverage);
        }
    }, [employerPlans, dependentsData, dependentsCoverage]);

    const handlePlanSelection = (plan, isSelected) => {
        setSelectedPlans(prev => ({
            ...prev,
            [plan.planId]: isSelected ? plan : null
        }));
        if (isSelected) {
            dispatch(addOrUpdateSelection({ ...plan, decision: 'enroll' }));
        } else {
            dispatch(addOrUpdateSelection({ ...plan, decision: 'waive' }));
        }
    };

    const handleDependentChange = (planId, dependentId) => {
        setDependentsCoverage(prev => ({
            ...prev,
            [planId]: {
                ...prev[planId],
                [dependentId]: !prev[planId]?.[dependentId]
            }
        }));
    };

    const handleWaiverReasonChange = (planId, reason) => {
        setWaiverReasons(prev => ({
            ...prev,
            [planId]: reason
        }));
    };



    const handleSubmitEnrollment = async () => {
        try {
            const selectedPlansAsArray = Object.values(selectedPlans).filter(plan => plan != null);

            if (selectedPlansAsArray.length === 0 && enrollmentObjectIchra == null) {
                setAlertMessage(translate('Please select at least one plan.'));
                setAlertSeverity('warning');
                return;
            }

            await submitEbToolkitEnrollmentObject(enrollWithDependents, enrollmentObjectIchra, userEmail, null);

            // Local Enrollment
            await Promise.all(
                selectedPlansAsArray.map(plan => {
                    const coveredDependents = dependentsData.filter(dep => dependentsCoverage[plan.planId]?.[dep.person.socialSecurityNumber]);
                    const planWithCoveredDependents = {
                        ...plan,
                        coveredDependents: coveredDependents,
                    }

                    let dto = formatLocalEnrollmentPlan(planWithCoveredDependents);
                    dto.employeeBenefitEnrollment = {
                        ...dto.employeeBenefitEnrollment,
                        signatureId: null,
                        changedBy: adminEmail, // Add the changedBy field here
                    };
                    return enrollWithDependents(dto).unwrap();
                })
            );

            setAlertMessage(translate('Enrollment submitted successfully!'));
            setAlertSeverity('success');
            onEnrollmentComplete();
        } catch (error) {
            console.error('Enrollment error:', error);
            setAlertMessage(translate('There was an error submitting the enrollment.'));
            setAlertSeverity('error');
        }
    };

    if (isPlansLoading || isDependentsLoading) {
        return <CircularProgress />;
    }

    if (!employerPlans || !dependentsData) {
        return <Typography variant="body1">Data could not be loaded. Please try again later.</Typography>;
    }

    return (
        <Container maxWidth="lg">
            <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
                <Typography variant="h5" gutterBottom>
                    {translate('Enroll User in Plans')}
                </Typography>
                {alertMessage && (
                    <Alert severity={alertSeverity} sx={{ mb: 2 }}>
                        {alertMessage}
                    </Alert>
                )}

                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="local-plans-content"
                        id="local-plans-header"
                    >
                        <Typography>{translate('Local Plans')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {employerPlans?.plans?.map((plan) => (
                            <Paper key={plan.planId} elevation={2} sx={{ p: 2, mb: 2 }}>
                                <Typography variant="h6">{plan.productName}</Typography>
                                <Typography variant="body2">{plan.description}</Typography>
                                <Typography variant="body2">Carrier: {plan.carrierName}</Typography>
                                <Typography variant="body2">Benefit Type: {plan.benefitTypeName}</Typography>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!!selectedPlans[plan.planId]}
                                            onChange={(e) => handlePlanSelection(plan, e.target.checked)}
                                        />
                                    }
                                    label={translate('Select this plan')}
                                />
                                {selectedPlans[plan.planId] && dependentsData && dependentsData.length > 0 && (
                                    <Box sx={{ mt: 2 }}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">{translate('Select Dependents Covered')}</FormLabel>
                                            <Typography variant="body2" sx={{ mb: 2, color: 'blue' }}>
                                                {translate('Please select the dependents covered by this plan')}
                                            </Typography>
                                            <FormGroup>
                                                {dependentsData.map(dependent => (
                                                    <FormControlLabel
                                                        key={dependent.person?.socialSecurityNumber}
                                                        control={
                                                            <Checkbox
                                                                checked={!!dependentsCoverage[plan.planId]?.[dependent.person?.socialSecurityNumber]}
                                                                onChange={() => handleDependentChange(plan.planId, dependent.person?.socialSecurityNumber)}
                                                                name={dependent.person?.firstName}
                                                            />
                                                        }
                                                        label={`${dependent.person?.firstName} ${dependent.person?.lastName}`}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                    </Box>
                                )}
                                {!selectedPlans[plan.planId] && (
                                    <TextField
                                        label={translate('Waiver Reason')}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={waiverReasons[plan.planId] || ''}
                                        onChange={(e) => handleWaiverReasonChange(plan.planId, e.target.value)}
                                    />
                                )}
                            </Paper>
                        ))}
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="ichra-plans-content"
                        id="ichra-plans-header"
                    >
                        <Typography>{translate('ICHRA Plans')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <EbIchraEnrollment
                            employerCode={employerCode}
                            userEmail={userEmail}

                            // The props below are used in the user enrollment components.
                            // We should probably refactor this when we have time.
                            hasPreviousPlanTypeStep={false}
                            onNext={ () => {} }
                            onPrevious={ () => {} }
                            setCurrentBenefitTypeIndex={ () => {} }
                            currentBenefitTypeIndex={ 0 }
                            currentBenefitType = {{typeName: "ICHRA"}}
                            showDependents={true}
                        />
                    </AccordionDetails>
                </Accordion>

                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmitEnrollment}
                        disabled={isEnrolling}
                    >
                        {isEnrolling ? translate('Submitting...') : translate('Submit Enrollment')}
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
};

export default EditUserEnrollment;
