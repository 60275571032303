import {
    useGetCostPerPayPeriodForEmployeeFromRateEbToolKitQuery,
} from "../reducers/enrollmentApiSlice";
import {formatCurrency} from "../utilities/formatCurrency";
import {useEffect} from "react";

export const useGetCostPerPayPeriodEbToolKit = (userEmail, plan) => {
    const { data: costPerPayPeriodData, isLoading: isCostPerPayPeriodDataLoading, isFetching, isError: isCostPerPayPeriodDataError, refetch, isUninitialized} = useGetCostPerPayPeriodForEmployeeFromRateEbToolKitQuery({
        userEmail: userEmail,
        rate: +(plan?.rate ?? 0)
    }, { skip: userEmail == null || plan == null});

    useEffect(() => {
        if (!isUninitialized) {
            refetch();
        }
    }, [plan]);

    if(plan == null || isFetching) {
        return {
            monthlyCostFormatted: null,
            costPerPayPeriodFormatted: 'N/A',
            annualCostFormatted: 'N/A',
            costPerPayPeriod: null,
            annualCost: null,
            isCostPerPayPeriodDataLoading: isCostPerPayPeriodDataLoading,
            isCostPerPayPeriodDataError: isCostPerPayPeriodDataError,
        }
    }

    const annualCost = costPerPayPeriodData?.data?.annualCost ?? null;
    const costPerPayPeriod = costPerPayPeriodData?.data?.costPerPayPeriod ?? null;

    let monthlyCost = 'N/A';
    if (annualCost != null) {
        monthlyCost = formatCurrency((annualCost / 12).toFixed(2));
    }

    return {
        monthlyCostFormatted: monthlyCost,
        costPerPayPeriodFormatted: costPerPayPeriod == null ? 'N/A' : formatCurrency(costPerPayPeriodData.data.costPerPayPeriod.toFixed(2)),
        annualCostFormatted: annualCost == null ? 'N/A' : formatCurrency(costPerPayPeriodData.data.annualCost.toFixed(2)),
        costPerPayPeriod: costPerPayPeriod,
        annualCost: annualCost,
        isCostPerPayPeriodDataLoading: isCostPerPayPeriodDataLoading || isFetching,
        isCostPerPayPeriodDataError: isCostPerPayPeriodDataError,
    }
};