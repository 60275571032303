import {useTheme} from "@mui/material/styles";
import useCustomTranslation from "../../../hooks/useCustomTranslation";
import {useGetCostPerPayPeriodEbToolKit} from "../../../hooks/useGetCostPerPayPeriodEbToolKit";
import {Box, Link, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@mui/material";
import {PictureAsPdf} from "@mui/icons-material";

const PlanDetailsTableEbToolkit = ({ userEmail, quote, containerStyle = {} }) => {
    if (!quote) return null;

    useTheme();
    const { translate: customTranslate } = useCustomTranslation();

    const {
        monthlyCostFormatted,
        costPerPayPeriodFormatted,
        annualCostFormatted,
        costPerPayPeriod,
        annualCost,
        isCostPerPayPeriodDataLoading,
        isCostPerPayPeriodDataError
    } = useGetCostPerPayPeriodEbToolKit(userEmail, quote);

    const detailRows = [
        { label: 'Carrier', value: quote.issuerName },
        { label: 'Plan', value: quote.planMarketingName || quote.planName },
        { label: 'Metal Level', value: quote.metalLevel },
        { label: 'Plan Type', value: quote.planType },
        { label: 'Deductible (Individual)', value: quote.annualDeductibleIndividual },
        { label: 'Deductible (Family)', value: quote.annualDeductibleFamily },
        { label: 'Out-of-Pocket Max (Individual)', value: quote.annualOopMaxIndividual },
        { label: 'Out-of-Pocket Max (Family)', value: quote.annualOopMaxFamily },
        { label: 'Monthly Premium', value: monthlyCostFormatted },
        { label: 'Premium Cost Per Pay Period', value: costPerPayPeriodFormatted },
        { label: 'Primary Care Visit', value: quote.primaryCareVisit },
        { label: 'Specialist Visit', value: quote.specialistVisit },
        { label: 'Urgent Care', value: quote.urgentCare },
        { label: 'Emergency Room Services', value: quote.emergencyRoomServices },
        { label: 'Generic Drugs', value: quote.genericDrugs },
        { label: 'Preferred Brand Drugs', value: quote.preferredBrandDrugs },
        { label: 'Non-Preferred Drugs', value: quote.nonPreferredDrugs },
        { label: 'Specialty Drugs', value: quote.specialtyDrugs },
        { label: 'HSA Eligible', value: quote.isHsaEligible },
    ];

    return (
        <Box sx={containerStyle}>
        <Typography id="plan-details-modal" variant="h6" component="h2" gutterBottom>
            Plan Details
        </Typography>

        <TableContainer component={Paper} sx={{ mt: 2, mb: 2 }}>
            <Table size="small">
                <TableBody>
                    {detailRows.map((row) => (
                        <TableRow key={row.label}>
                            <TableCell component="th" scope="row">
                                <strong>{customTranslate(row.label)}</strong>
                            </TableCell>
                            <TableCell align="right">{row.value || 'N/A'}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
            {quote.urlForBenefitSummaryCoverage && (
                    <Link
                        href={quote.urlForBenefitSummaryCoverage}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}
                    >
                        <PictureAsPdf sx={{ mr: 1 }} />
                        View Full Benefits Summary
                    </Link>
                )}
        </Box>
    );
};

export default PlanDetailsTableEbToolkit;