import piedmontConfig from './piedmontConfig';
import React, { useEffect, useRef, useState } from 'react';
import { useGetEmployeePaymentInfoQuery } from "../../reducers/enrollmentApiSlice";

// Function to generate a SHA-256 hash from a given string using the Web Crypto API
async function generateSha256Hash(input) {
    console.log(`[generateSha256Hash] Generating SHA-256 hash for input: ${input}`);
    const msgBuffer = new TextEncoder().encode(input);
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hash = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    console.log(`[generateSha256Hash] Generated hash: ${hash}`);
    return hash;
}

const PiedmontIntegration = ({ email, year }) => {
    const piedmontRef = useRef(null);
    const iframeContainerRef = useRef(null);

    const [referenceId, setReferenceId] = useState(null);
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const [customerData, setCustomerData] = useState(null);

    // Fetch data from the API
    const { data, isLoading, isError, error } = useGetEmployeePaymentInfoQuery({ year, email });

    useEffect(() => {
        if (isLoading) {
            console.log(`[useGetEmployeePaymentInfoQuery] Loading payment info for email: ${email} and year: ${year}`);
        } else if (isError) {
            console.error(`[useGetEmployeePaymentInfoQuery] Error fetching payment info: ${error}`);
        } else if (data) {
            console.log(`[useGetEmployeePaymentInfoQuery] Received payment info:`, data);
            const {
                firstName,
                lastName,
                emailAddress,
                cellPhoneNumber,
                homePhoneNumber,
                lastFourSSN,
                paymentFrequency,
                premiumAmount,
                firstPayment,
                paymentMethod,
            } = data;

            setCustomerData({
                groupnumber: process.env.REACT_APP_PIEDMONT_GROUP_NUMBER || piedmontConfig.REACT_APP_PIEDMONT_GROUP_NUMBER,
                firstname: firstName,
                lastname: lastName,
                emailaddress: emailAddress,
                cellphonenumber: cellPhoneNumber,
                homephonenumber: homePhoneNumber,
                lastfourssn: lastFourSSN,
                paymentfrequency: paymentFrequency,
                premiumamount: premiumAmount,
                firstpayment: firstPayment,
                paymentmethod: paymentMethod,
            });
        }
    }, [data, isLoading, isError, error, email, year]);

    useEffect(() => {
        const generateReference = async () => {
            if (!customerData) {
                console.log('[generateReference] Waiting for customer data to generate reference ID');
                return;
            }

            const uniqueInput = `${customerData.firstname}${customerData.lastname}${customerData.lastfourssn}`;
            try {
                const hash = await generateSha256Hash(uniqueInput);
                setReferenceId(hash);
            } catch (error) {
                console.error('[generateReference] Error generating hash:', error);
            }
        };
        generateReference();
    }, [customerData]);

    useEffect(() => {
        if (referenceId) {
            if (window.PiedmontConnect) {
                console.log('[PiedmontConnect] Initializing PiedmontConnect instance');
                piedmontRef.current = new window.PiedmontConnect({
                    apikey: process.env.REACT_APP_PIEDMONT_API_KEY || piedmontConfig.REACT_APP_PIEDMONT_API_KEY,
                    referenceid: referenceId,
                    complete_callback: (response) => {
                        console.log('[PiedmontConnect] Completion response:', response);
                    //    window.location.replace('/pdf-viewer');
                    },
                });
            } else {
                console.error('[PiedmontConnect] SDK not loaded');
            }
        }
    }, [referenceId]);

    const handleStartAuthorization = () => {
        if (!piedmontRef.current) {
            console.error('[handleStartAuthorization] PiedmontConnect instance not initialized');
            return;
        }

        if (!customerData) {
            console.error('[handleStartAuthorization] Customer data not available');
            return;
        }

        console.log('[handleStartAuthorization] Starting authorization with customer data:', customerData);

        piedmontRef.current.initPiedmontProxy({
            dom: iframeContainerRef.current,
            data: customerData,
        });

        setTimeout(() => {
            const iframe = iframeContainerRef.current.querySelector('iframe');
            if (iframe) {
                iframe.onload = () => {
                    console.log('[handleStartAuthorization] Iframe loaded');
                    setIframeLoaded(true);
                };
            } else {
                console.error('[handleStartAuthorization] Iframe not found');
            }
        }, 1000);
    };

    useEffect(() => {
        if (referenceId && piedmontRef.current) {
            handleStartAuthorization();
        }
    }, [referenceId, customerData]);

    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                margin: 0,
                padding: 0,
                position: 'relative',
                overflow: 'hidden',
                backgroundColor: '#ffffff',
            }}
        >
            <div
                ref={iframeContainerRef}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    zIndex: 9999,
                }}
            ></div>
        </div>
    );
};

export default PiedmontIntegration;
