// CensusDataDownloader.jsx
import React, { useEffect, useState, useCallback, memo } from 'react';
import { Button, CircularProgress, Alert, Box } from '@mui/material';
import { useExportCensusDataByEmployerQuery } from "../../reducers/enrollmentApiSlice";
import useCustomTranslation from '../../hooks/useCustomTranslation';

const CensusDataDownloader = memo(({ employerCode }) => {
    const { translate } = useCustomTranslation();
    const [shouldFetch, setShouldFetch] = useState(false);

    // ---- Added validation & structured logging for employerCode ----
    if (!employerCode || typeof employerCode !== 'string' || !employerCode.trim()) {
        console.error(`[CensusDataDownloader] Invalid employerCode: ${JSON.stringify(employerCode)}`);
    }

    // Updated log to use JSON.stringify
    console.log(
        `Component Rendered - Employer Code: ${JSON.stringify(employerCode)}, shouldFetch: ${shouldFetch}`
    );

    const { data: censusData, isLoading, error } = useExportCensusDataByEmployerQuery(
        employerCode,
        { skip: !shouldFetch || !employerCode }
    );

    // Updated log to be more structured
    console.log(
        `Query State - shouldFetch: ${shouldFetch}, isLoading: ${isLoading}, error: ${JSON.stringify(error)}`
    );
    console.log(`censusData: ${JSON.stringify(censusData, null, 2)}`);

    const downloadCsv = useCallback(
        (base64Csv) => {
            try {
                console.log("[CensusDataDownloader] Initiating CSV download...");
                const decodedCsv = atob(base64Csv);
                const blob = new Blob([decodedCsv], { type: 'text/csv' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `CensusData_${employerCode}.csv`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);
                console.log("[CensusDataDownloader] CSV download complete.");
            } catch (err) {
                console.error("[CensusDataDownloader] Failed to process CSV data:", err);
            }
        },
        [employerCode]
    );

    useEffect(() => {
        console.log(`[CensusDataDownloader] useEffect triggered - isLoading: ${isLoading}`);
        if (!isLoading) {
            if (censusData && censusData.Base64Csv) {
                console.log("[CensusDataDownloader] Census data available, triggering download.");
                downloadCsv(censusData.Base64Csv);
                setShouldFetch(false);
                console.log("[CensusDataDownloader] Fetch reset after download.");
            } else if (error || (censusData && censusData.error)) {
                console.error(
                    "[CensusDataDownloader] Error fetching census data:",
                    JSON.stringify(error || censusData.error, null, 2)
                );
            } else {
                console.warn("[CensusDataDownloader] Census data is undefined or missing Base64Csv field.");
            }
        }
    }, [isLoading, censusData, error, downloadCsv]);

    const handleExportClick = () => {
        console.log("[CensusDataDownloader] Export button clicked, setting shouldFetch to true.");
        setShouldFetch(true);
    };

    return (
        <Box sx={{ my: 2 }}>
            <Button
                variant="contained"
                color="primary"
                onClick={handleExportClick}
                disabled={isLoading || !employerCode}
            >
                {translate('Export Census Data')}
            </Button>
            {isLoading && <CircularProgress size={24} sx={{ ml: 2 }} />}
            {(error || (censusData && censusData.error)) && (
                <Alert severity="error">
                    {translate('Failed to export census data')}
                </Alert>
            )}
        </Box>
    );
});

export default CensusDataDownloader;
