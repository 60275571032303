import React, {useState, useCallback, useMemo} from 'react';
import {Box, useMediaQuery, useTheme, Grid, Modal, Alert, Typography} from '@mui/material';
import QuoteFiltersHSA from './QuoteFiltersHSA';
import QuoteRowHSA from './QuoteRowHSA';
import useCustomTranslation from "../../../hooks/useCustomTranslation";
import ICHRAPlanDetailsModalHSA from "./HSAModals/ICHRAPlanDetailsModalHSA";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import PlanComparisonHsa from "./PlanComparisonHsa";

const QuoteListHSA = ({ userEmail, quotes, handleEnroll, handleAddToCompareList,
                          plansToCompare, showPlansToCompareModal, setShowPlansToCompareModal, isSelectedForComparison }) => {
    const { translate } = useCustomTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const selectedDependents = useSelector((state) => state.hsa.selectedDependents);
    const selectedPlan = useSelector((state) => state.hsa.selectedPlanInfo);
    const [currentlyViewedPlan, setCurrentlyViewedPlan] = useState(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const [filters, setFilters] = useState({
        selectedCarrier: '',
        rateRange: [0, Math.max(...quotes.map(quote => quote.censusTotal))],
        selectedMetalLevel: '',
        selectedPlanType: '',
        isHsaEligible: '',
    });

    // Modal states:
    const [planDetailsDialogOpen, setPlanDetailsDialogOpen] = useState(false);

    const handleSelectQuote = (quote) => {
        // Toggle the selected plan.
        if(quote === selectedPlan) {
            handleEnroll(null);
        } else {
            handleEnroll(quote);
        }
        setPlanDetailsDialogOpen(false);
    };

    const handlePlanDetails = (quote) => {
        setCurrentlyViewedPlan(quote);
        setPlanDetailsDialogOpen(true);
    };

    const handlePlanDetailsDialogClose = () => {
        setCurrentlyViewedPlan(null);
        setPlanDetailsDialogOpen(false);
    }

    const handleFilterChange = useCallback((newFilters) => {
        setFilters(newFilters);
    }, []);

    const filteredQuotes = useMemo(() => {
        return quotes?.filter(quote => {
            // [filters.rateRange[0], filters.rateRange[1]]
            const priceWithinMonthlyCostRange = quote.censusTotal >= filters.rateRange[0] && quote.censusTotal <= filters.rateRange[1];
            const isAllCarriersSelected = filters.selectedCarrier === '';
            const isSameCarrierName = quote.carrierName === filters.selectedCarrier || isAllCarriersSelected;
            const filterByMetalLevel = filters.selectedMetalLevel === '' || quote.webLevel === filters.selectedMetalLevel;
            const filterByPlanType = filters.selectedPlanType === '' || quote.typePlan === filters.selectedPlanType;

            const hsaEligibility = quote.hsaYN.toLowerCase() === "y" ? "yes" : "no";
            const filterByHsaEligibility = filters.isHsaEligible === '' || (hsaEligibility === filters.isHsaEligible.toLowerCase());

            return (
                priceWithinMonthlyCostRange &&
                isSameCarrierName &&
                filterByMetalLevel &&
                filterByPlanType &&
                filterByHsaEligibility
            );
        }) ?? [];
        }, [filters, quotes]
    );

    return (
                <>
                    <Grid item xs={12} key={'quote-filters-hsa-container'}>
                        <Box sx={{ p: 2 }}>
                            <QuoteFiltersHSA quotes={quotes} filters={filters} onFilterChange={handleFilterChange} />
                        </Box>
                    </Grid>
                    {filteredQuotes.length === 0 ?
                        <Grid item xs={12} key={'0 plans found'} sx={{ minWidth: '320px' }}>
                            <Alert severity="info" sx={{ width: '100%', mb: 2 }}>
                                <Typography>{translate('0 plans found with the provided filters.')}</Typography>
                            </Alert>
                        </Grid>
                        : filteredQuotes.map((quote, index) => (
                        <Grid item xs={12} sm={12} md={6} key={index} sx={{ minWidth: '320px' }}>
                            <QuoteRowHSA
                                key={index}
                                quote={quote}
                                isSelected={((selectedPlan?.hiosid) ?? null) === quote.hiosid}
                                onSelect={() => handleSelectQuote(quote)}
                                onPlanDetailsClick={() => handlePlanDetails(quote)}
                                isMobile={isMobile}
                                isTablet={isTablet}
                                handleAddToCompareList={handleAddToCompareList}
                                isSelectedForComparison={isSelectedForComparison(quote)}
                            />
                        </Grid>
                    ))}

                    {currentlyViewedPlan && (
                        <>
                            <ICHRAPlanDetailsModalHSA userEmail={userEmail} open={planDetailsDialogOpen} onClose={() => {handlePlanDetailsDialogClose()}} quote={currentlyViewedPlan} onSelectQuote={handleSelectQuote} />
                        </>
                    )}

                    {showPlansToCompareModal && plansToCompare.length >= 2 && (
                        <Modal open={showPlansToCompareModal} onClose={() => setShowPlansToCompareModal(false)} sx={{ height: '90vh', maxWidth: '95vw', mx: 'auto'}}>
                            <PlanComparisonHsa userEmail={userEmail} plans={plansToCompare} onPlanSelect={handleSelectQuote} currentlySelectedPlanHiosId={(selectedPlan?.hiosid) ?? null} />
                        </Modal>
                    )}
                </>
    );
};

QuoteListHSA.propTypes = {
    quotes: PropTypes.array.isRequired,
};


export default QuoteListHSA;
