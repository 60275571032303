import {Alert, Box, Button, Checkbox, CircularProgress, Container, FormControlLabel, Grid, Paper, Snackbar, Typography, useMediaQuery, useTheme} from "@mui/material";
import Branding from "../Branding";
import logoImage from "../../assets/logo/default_logo.png";
import {formatCurrency} from "../../utilities/formatCurrency";
import SelectedPlanReadOnlyHsa from "../ICHRA/HSA/SelectedPlanReadOnlyHsa";
import SelectedPlanReadOnlyIchra from "../ICHRA/Ebtoolkit/SelectedPlanReadOnlyIchra";
import AgreeAndSignModal from "./AgreeAndSignModal";
import React from "react";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import SignatureCanvas from "react-signature-canvas";

const AgreeAndSignPageView = ({
                                  userEmail,
                                  isLoading, isError: _isError,
                                  activeEmployerDisclosure,
                                  rateCostsPerPayPeriod,
                                  modalOpen, modalMessage, handleCloseModal,
                                  snackbar, handleCloseSnackbar,
                                  selectedPlansWithDependents,
                                  selectedPlanHsa, selectedDependentsHsa, waiveHsa,
                                  selectedPlanIchra, selectedDependentsIchra, waiveIchra,
                                  sigCanvas, signature, handleClearSignature, handleSaveSignature,
                                  agreeToTerms, handleAgreeChange,
                                  formatEnrollmentData,
                                  handleSubmit, isEnrolling,
                                  personalDetails
                              }) => {

    const { translate } = useCustomTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Verbose logging with [AGREE AND SIGN PAGE VIEW] prefix
    console.log("[AGREE AND SIGN PAGE VIEW] AgreeAndSignPageView props:", {
        isLoading,
        isError: _isError,
        activeEmployerDisclosure,
        rateCostsPerPayPeriod,
        modalOpen, modalMessage,
        selectedPlansWithDependents,
        selectedPlanHsa, selectedDependentsHsa, waiveHsa,
        selectedPlanIchra, selectedDependentsIchra, waiveIchra,
        signature,
        agreeToTerms,
        personalDetails
    });

    if (isLoading) {
        console.log("[AGREE AND SIGN PAGE VIEW] Loading state detected. Displaying CircularProgress...");
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                <CircularProgress />
            </Box>
        );
    }

    // Log the raw rateCostsPerPayPeriod object with [AGREE AND SIGN PAGE VIEW] prefix
    console.log("[AGREE AND SIGN PAGE VIEW] rateCostsPerPayPeriod:", rateCostsPerPayPeriod);

    return (
        <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
            <Paper elevation={3} sx={{ p: { xs: 2, sm: 4 }, mt: 4 }}>
                <Box sx={{ textAlign: 'center', mb: 4 }}>
                    <Branding logoUrl={logoImage} width={isMobile ? '200px' : '250px'} height="75px" alt="Company Logo" />
                    <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
                        {translate('Agreement and Signature')}
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                        {translate('Please review your selections and sign the agreement.')}
                    </Typography>
                </Box>

                <Grid container spacing={2}>
                    {selectedPlansWithDependents.map((plan, index) => {
                        console.log("[AGREE AND SIGN PAGE VIEW] Processing plan:", plan);

                        const isLifeInsurance = plan.benefitTypeId === 4;
                        const formattedEnrollmentData = formatEnrollmentData(plan);

                        // Because the spouse in your example is only a beneficiary (not a covered dependent),
                        // your plan might still return coverageTier: "Employee + Spouse" on the front end,
                        // but the back-end rate array expects "Employee".
                        // Quick workaround: override coverage tier to "Employee" so we match the rate data.
                        if (isLifeInsurance) {
                            console.log("[AGREE AND SIGN PAGE VIEW] Quick workaround: overriding coveredIndividuals to 'Employee' for life plan with beneficiary spouse.");
                            formattedEnrollmentData.coveredIndividuals = "Employee";
                        }

                        // If there's a beneficiary for life insurance
                        const beneficiary = isLifeInsurance && plan.coveredDependents
                            ? Array.isArray(plan.coveredDependents)
                                ? plan.coveredDependents.find(dep => dep.isCovered)?.person
                                : Object.values(plan.coveredDependents).find(dep => dep.isCovered)?.person
                            : null;

                        // Filter coveredDependents
                        const coveredDependents = Array.isArray(plan.coveredDependents)
                            ? plan.coveredDependents.filter(dep => dep.isCovered)
                            : Object.values(plan.coveredDependents).filter(dep => dep.isCovered);

                        let costPerPayPeriod = 'N/A';
                        let annualCost = 'N/A';
                        const rateCostsPerPayPeriodData = rateCostsPerPayPeriod?.data ?? {};

                        // Ensure planId exists in rateCostsPerPayPeriodData
                        if (rateCostsPerPayPeriodData[plan.planId]) {
                            console.log("[AGREE AND SIGN PAGE VIEW] Found rateCosts for planId", plan.planId, ":", rateCostsPerPayPeriodData[plan.planId]);
                            for (const rateCostPerPayPeriodObj of rateCostsPerPayPeriodData[plan.planId]) {
                                const coverageTierMatch =
                                    rateCostPerPayPeriodObj?.coverageTier === (formattedEnrollmentData?.coveredIndividuals ?? '');
                                const smokerMatch =
                                    (rateCostPerPayPeriodObj?.smokingStatus ?? false) === (personalDetails?.isSmoker ?? false);

                                console.log("[AGREE AND SIGN PAGE VIEW] Checking coverage tier and smoker match:", {
                                    coverageTierMatch,
                                    coverageTierNeeded: formattedEnrollmentData?.coveredIndividuals,
                                    coverageTierInRate: rateCostPerPayPeriodObj?.coverageTier,
                                    smokerMatch,
                                    smokerNeeded: personalDetails?.isSmoker ?? false,
                                    smokerInRate: rateCostPerPayPeriodObj?.smokingStatus ?? false
                                });

                                if (coverageTierMatch && smokerMatch) {
                                    const costPerPayPeriodValue = rateCostPerPayPeriodObj?.costPerPayPeriod?.toFixed(2) ?? '0.00';
                                    const annualCostValue = rateCostPerPayPeriodObj?.annualCost?.toFixed(2) ?? '0.00';

                                    console.log("[AGREE AND SIGN PAGE VIEW] Matched rateCostPerPayPeriod object:", rateCostPerPayPeriodObj);

                                    costPerPayPeriod = formatCurrency(costPerPayPeriodValue);
                                    annualCost = formatCurrency(annualCostValue);
                                }
                            }
                        }

                        return (
                            <Grid item xs={12} key={index}>
                                <Paper variant="outlined" sx={{ p: 2, backgroundColor: theme.palette.background.default }}>
                                    <Typography variant="h6" sx={{ mb: 1, color: theme.palette.primary.main }}>
                                        {plan.productName} {plan.isWaived ? `(${translate('Waived')})` : ''}
                                    </Typography>
                                    <Typography variant="body2" sx={{ mb: 1 }}>
                                        {plan.benefitTypeName}
                                    </Typography>
                                    {!plan.isWaived && (
                                        <>
                                            <Typography variant="body2">
                                                <strong>{translate('Carrier')}:</strong> {plan.carrierName}
                                            </Typography>

                                            {isLifeInsurance && (
                                                <Typography variant="body2">
                                                    <strong>{translate('Coverage Amount')}:</strong> ${(plan.coverageAmount || 0).toLocaleString()}
                                                </Typography>
                                            )}

                                            {!isLifeInsurance ? (
                                                <Typography variant="body2">
                                                    <strong>{translate('Covered Individuals')}:</strong>{' '}
                                                    {formattedEnrollmentData.coveredIndividuals}
                                                </Typography>
                                            ) : null}

                                            {!isLifeInsurance ? (
                                                <Typography variant="body2">
                                                    <strong>{translate('Covered Dependents')}:</strong>{' '}
                                                    {Array.isArray(plan.coveredDependents)
                                                        ? coveredDependents.map(dep => `${dep.person.firstName} ${dep.person.lastName}`).join(', ')
                                                        : coveredDependents.map(dep => `${dep.person.firstName} ${dep.person.lastName}`).join(', ') || translate('None')}
                                                </Typography>
                                            ) : null}

                                            <Typography variant="body2">
                                                <strong>{translate('Annual Cost')}:</strong>{' '}
                                                {annualCost}
                                            </Typography>
                                            <Typography variant="body2">
                                                <strong>{translate('Cost Per Pay Period')}:</strong>{' '}
                                                {costPerPayPeriod}
                                            </Typography>

                                            {isLifeInsurance && beneficiary && (
                                                <Typography variant="body2">
                                                    <strong>{translate('Beneficiary')}:</strong>{' '}
                                                    {`${beneficiary.firstName} ${beneficiary.lastName}`}
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                    {plan.isWaived && (
                                        <Typography variant="body2">
                                            <strong>{translate('Waiver Reason')}:</strong> {plan.waiverReason || translate('No reason provided')}
                                        </Typography>
                                    )}
                                </Paper>
                            </Grid>
                        );
                    })}

                    <SelectedPlanReadOnlyHsa userEmail={userEmail} plan={selectedPlanHsa} selectedDependents={selectedDependentsHsa} waiveData={waiveHsa} />
                    <SelectedPlanReadOnlyIchra userEmail={userEmail} plan={selectedPlanIchra} selectedDependents={selectedDependentsIchra} waiveData={waiveIchra} />

                    { activeEmployerDisclosure != null && activeEmployerDisclosure?.data != null ? (
                        <Grid item xs={12}>
                            <Paper variant="outlined" sx={{ p: 2, backgroundColor: theme.palette.background.default }}>
                                <Typography variant="h2" sx={{  mb: 2}}>
                                    {translate('Employer Disclosure')}
                                </Typography>

                                <Box sx={{ overflowY: 'scroll', maxHeight: '300px'}}>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            paddingRight: 2,
                                            whiteSpace: 'pre-wrap',
                                            wordBreak: 'break-word'
                                        }}
                                    >
                                        {activeEmployerDisclosure.data.disclosureText}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    ) : null}

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={agreeToTerms}
                                    onChange={handleAgreeChange}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'Agree to terms and conditions' }}
                                />
                            }
                            label={translate('I agree to the terms and conditions')}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            {translate('Signature')}
                        </Typography>
                        <Box
                            sx={{
                                border: '2px dashed grey',
                                borderRadius: 1,
                                mb: 2,
                                position: 'relative',
                                width: '100%',
                                height: { xs: 150, sm: 200, md: 250, lg: 300 },
                                overflow: 'hidden'
                            }}
                        >
                            <SignatureCanvas
                                ref={sigCanvas}
                                penColor="black"
                                canvasProps={{
                                    className: 'sigCanvas',
                                    style: {
                                        width: '100%',
                                        height: '100%',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        touchAction: 'none'
                                    }
                                }}
                                onEnd={handleSaveSignature}
                            />
                            {!signature && (
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        pointerEvents: 'none',
                                        zIndex: 1
                                    }}
                                >
                                    {translate('Please provide your signature above')}
                                </Typography>
                            )}
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                onClick={handleClearSignature}
                                variant="outlined"
                                color="secondary"
                                aria-label="Clear signature"
                                disabled={!signature}
                            >
                                {translate('Clear Signature')}
                            </Button>
                            <Button
                                onClick={handleSubmit}
                                variant="contained"
                                color="primary"
                                aria-label="Submit enrollment"
                                disabled={isEnrolling || !agreeToTerms || !signature}
                            >
                                {isEnrolling ? <CircularProgress size={24} /> : translate('Submit Enrollment')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

            <AgreeAndSignModal open={modalOpen} onClose={handleCloseModal} message={modalMessage} />

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );

};

export default AgreeAndSignPageView;
