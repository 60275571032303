import React, {useState} from 'react';
import {Box, Grid, Typography, useTheme} from '@mui/material';
import Branding from '../../Branding';
import useCustomTranslation from "../../../hooks/useCustomTranslation";
import PlanSelectionPlanName from "../../PlanSelection/PlanSelectionPlanName";
import PlanSelectionPlanLevelTag from "../../PlanSelection/PlanSelectionPlanLevelTag";
import PlanSelectionRowInfoField from "../../PlanSelection/PlanSelectionRowInfoField";
import shortenPlanName from "../../../utilities/shortenPlanName";
import PlanSelectionSummaryButton from "../../PlanSelection/PlanSelectionSummaryButton";
import PlanSelectionEnrollButton from "../../PlanSelection/PlanSelectionEnrollButton";
import PlanSelectionRowInfoFieldLabel from "../../PlanSelection/PlanSelectionRowInfoFieldLabel";
import PlanSelectionPaper from "../../PlanSelection/PlanSelectionPaper";
import {gowellColors} from "../../../themes/palettes/gowellColors";
import PlanSelectionCompareButton from "../../PlanSelection/PlanSelectionCompareButton";
import DependentCoverage from "../../ReviewBenefitPage/DependentCoverage";

const QuoteRowEbToolkit = ({ dependentsData, quote, isSelected, onSelect, onOpenDetails, isMobile, isTablet, handleAddToCompareList, isSelectedForComparison, showDependents = false }) => {
    const theme = useTheme();
    const { translate } = useCustomTranslation();

    const monthlyPremium = +quote.rate || 0;

    const backgroundColor = isSelectedForComparison ? {
        backgroundColor: gowellColors.grey.dark
    } : {};

    const [dependentsCoverage, setDependentsCoverage] = useState({});

    const handleDependentCoverageChange = (planId, dependent, isCovered) => {
        setDependentsCoverage((prevState) => {
            return {
                ...prevState,
                [+dependent.dependentId]: {
                    ...dependent,
                    isCovered: isCovered,
                }
            };
        });
    };

    return (
        <PlanSelectionPaper  additionalStyles={{
            border: isSelectedForComparison ? `2px solid ${gowellColors.blue.base}` : 'none',
            ...backgroundColor,
        }}>
            <Grid padding={4} item spacing={2} alignItems="center" justifyContent="center">

                <Grid item xs={12} display="flex" justifyContent="center" sx={{ width: '100%' }}>
                    <PlanSelectionCompareButton handleAddToCompareList={handleAddToCompareList} quote={quote} isSelectedForComparison={isSelectedForComparison}  />
                </Grid>
                
                <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '1rem' }}>
                    <PlanSelectionPlanName planName={quote.planMarketingName || quote.planName} />
                    <PlanSelectionPlanLevelTag planType={quote.metalLevel} />
                </Grid>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '1rem', marginTop: '2rem', marginBottom: '2rem' }}>

                    <PlanSelectionRowInfoField label={'Carrier: '} text={shortenPlanName(shortenPlanName(quote.issuerName))} />
                    <PlanSelectionRowInfoField label={'Annual Deductible: '} text={shortenPlanName(quote.annualDeductibleIndividual)} />
                    <PlanSelectionRowInfoField label={'Out of Pocket Max: '} text={shortenPlanName(quote.annualOopMaxIndividual)} />

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <PlanSelectionRowInfoFieldLabel text={'Monthly Premium: '} />
                        <Typography variant="h6">${monthlyPremium.toFixed(2)}</Typography>
                    </Box>

                </Box>

                <Box align="center">
                    <PlanSelectionSummaryButton  onPlanDetailsClick={() => onOpenDetails(quote)}/>

                    {showDependents ?
                        <DependentCoverage
                            planId={quote.planId}
                            dependentsData={dependentsData}
                            dependentsCoverage={dependentsCoverage}
                            onDependentChange={handleDependentCoverageChange}
                        />
                    : null}

                    <PlanSelectionEnrollButton isSelected={isSelected} onSelect={() => onSelect(quote, dependentsCoverage)} />
                </Box>

            </Grid>

        </PlanSelectionPaper>
    );
};

export default QuoteRowEbToolkit;
