import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    useGetEmployerAdminRatesByPlanIdQuery,
    useGetAllContributionTypesQuery,
    useGetBenefitTypesQuery,
    useGetAllRateTypesQuery,
    useGetAllIndividualTypesQuery,
    useGetCostsPerPayPeriodForLocalPlansQuery
} from '../../reducers/enrollmentApiSlice';
import {
    Box,
    Typography,
    Alert,
    Paper,
    ToggleButtonGroup,
    ToggleButton,
    useMediaQuery,
    useTheme,
    List,
    ListItem,
    ListItemText,
    Divider,
    TextField,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import LoadingSpinner from "../LoadingSpinner";
// We use NoRatesOverlay here instead of NoRowsOverlay
import NoRatesOverlay from './NoRatesOverlay';
import AuthTokenService from "../../services/AuthTokenService";
import { getEmployerAdminRateColumns, getActiveFieldsForRateType } from './EmployerAdminRates/employerAdminRateColumns';

const ReadOnlyEmployerAdminRatesTable = ({ plan, employerCode, showRateCostsPerPayPeriod = false }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const email = AuthTokenService.getAuthInfo().user || '';

    console.log("ReadOnlyEmployerAdminRatesTable: Starting with plan:", plan, "employerCode:", employerCode, "showRateCostsPerPayPeriod:", showRateCostsPerPayPeriod);

    // -- API QUERIES --
    const {
        data: employerAdminRatesData,
        error,
        isLoading: employerAdminRatesDataIsLoading
    } = useGetEmployerAdminRatesByPlanIdQuery({
        planId: plan.planId,
        email: email,
        employerCode: employerCode
    });

    const {
        data: rateCostsPerPayPeriod,
        error: rateCostsPerPayPeriodError,
        isLoading: rateCostsPerPayPeriodIsLoading
    } = useGetCostsPerPayPeriodForLocalPlansQuery(
        {
            planIds: [plan.planId],
            email: email,
        },
        { skip: !showRateCostsPerPayPeriod }
    );

    const {
        data: contributionTypesData,
        error: contributionTypesError,
        isLoading: contributionTypesLoading
    } = useGetAllContributionTypesQuery();

    const {
        data: benefitTypesData,
        error: benefitTypesError,
        isLoading: benefitTypesLoading
    } = useGetBenefitTypesQuery();

    const {
        data: rateTypesData,
        error: rateTypesError,
        isLoading: rateTypesLoading
    } = useGetAllRateTypesQuery();

    const {
        data: individualTypesData,
        error: individualTypesError,
        isLoading: individualTypesLoading
    } = useGetAllIndividualTypesQuery();

    console.log("ReadOnlyEmployerAdminRatesTable: Fetched data => employerAdminRatesData:", employerAdminRatesData);
    console.log("ReadOnlyEmployerAdminRatesTable: Fetched data => rateCostsPerPayPeriod:", rateCostsPerPayPeriod);
    console.log("ReadOnlyEmployerAdminRatesTable: Fetched data => contributionTypesData:", contributionTypesData);
    console.log("ReadOnlyEmployerAdminRatesTable: Fetched data => benefitTypesData:", benefitTypesData);
    console.log("ReadOnlyEmployerAdminRatesTable: Fetched data => rateTypesData:", rateTypesData);
    console.log("ReadOnlyEmployerAdminRatesTable: Fetched data => individualTypesData:", individualTypesData);

    // -- LOCAL STATES --
    const [rateType, setRateType] = useState('all');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedContributionType, setSelectedContributionType] = useState('');
    const [benefitType, setBenefitType] = useState('');

    // -- SET BENEFIT TYPE NAME --
    useEffect(() => {
        if (benefitTypesData && plan.benefitTypeId) {
            const matchedBenefitType = benefitTypesData.find(
                bt => bt.benefitTypeId === plan.benefitTypeId
            );
            if (matchedBenefitType) {
                setBenefitType(matchedBenefitType.typeName);
                console.log("ReadOnlyEmployerAdminRatesTable: Set benefitType to:", matchedBenefitType.typeName);
            }
        }
    }, [benefitTypesData, plan.benefitTypeId]);

    // -- PRE-SELECT CONTRIBUTION TYPE & RATE TYPE --
    useEffect(() => {
        if (employerAdminRatesData && employerAdminRatesData.length > 0) {
            setSelectedContributionType(employerAdminRatesData[0].contributionTypeId.toString());
            setRateType(employerAdminRatesData[0].rateTypeName.toLowerCase());
            console.log(
                "ReadOnlyEmployerAdminRatesTable: Set default contributionType:",
                employerAdminRatesData[0].contributionTypeId,
                "and rateType:",
                employerAdminRatesData[0].rateTypeName.toLowerCase()
            );
        }
    }, [employerAdminRatesData]);

    // -- FORMAT THE PARENT DATA INTO 'formattedRates' --
    const formattedRates = useMemo(() => {
        if (!employerAdminRatesData || employerAdminRatesData.length === 0 || !contributionTypesData) {
            console.log("ReadOnlyEmployerAdminRatesTable: formattedRates => No data to format.");
            return [];
        }

        const results = employerAdminRatesData.map(rate => {
            // find the contribution type name
            const contributionType = contributionTypesData.find(
                (type) => type.contributionTypeId === rate.contributionTypeId
            );

            // if showRateCostsPerPayPeriod is enabled, find cost
            const rateCostsPerPayPeriodData = rateCostsPerPayPeriod?.data?.[plan.planId] ?? [];
            const rateCostPerPayPeriod = rateCostsPerPayPeriodData.find(
                (rc) => +rc.rateId === +rate.rateId
            );

            // basic fields
            const rowObj = {
                ...rate,
                effectiveDate: rate.effectiveDate
                    ? new Date(rate.effectiveDate).toISOString().split('T')[0]
                    : '',
                expirationDate: rate.expirationDate
                    ? new Date(rate.expirationDate).toISOString().split('T')[0]
                    : '',
                rateId: rate.rateId || `temp-${Math.random()}`,
                rate: parseFloat(rate.rate),
                smokingStatus: rate.smokingStatus ? 'Yes' : 'No',
                isActive: rate.isActive ? 'Yes' : 'No',
                contributionTypeName: contributionType ? contributionType.typeName : 'N/A',
                contributionValueFormatted:
                    rate.contributionValue !== null
                        ? `$${rate.contributionValue.toFixed(2)}`
                        : 'N/A',
                benefitTypeName: rate?.benefitType?.typeName || 'N/A',
                eoiMax:
                    rate.eoiMax !== null
                        ? `$${rate.eoiMax.toFixed(2)}`
                        : 'N/A',
                increments:
                    rate.increments !== null
                        ? `$${rate.increments.toFixed(2)}`
                        : 'N/A',
                min:
                    rate.min !== null
                        ? `$${rate.min.toFixed(2)}`
                        : 'N/A',
                max:
                    rate.max !== null
                        ? `$${rate.max.toFixed(2)}`
                        : 'N/A',
                ageBand: rate.ageBand || 'N/A',
                // store the array
                termLifeRates: Array.isArray(rate.termLifeRates) ? rate.termLifeRates : [],
                costPerPayPeriod:
                    rateCostPerPayPeriod?.costPerPayPeriod?.toFixed(2) ?? 'N/A',
            };
            return rowObj;
        });

        console.log("ReadOnlyEmployerAdminRatesTable: formattedRates =>", results);
        return results;
    }, [
        employerAdminRatesData,
        contributionTypesData,
        rateCostsPerPayPeriod,
        plan.planId
    ]);

    // -- DETECT IF ANY ROW HAS TERM LIFE RATES --
    const hasAnyTermLifeRates = useMemo(() => {
        return formattedRates.some(
            (parentRow) => parentRow.termLifeRates && parentRow.termLifeRates.length > 0
        );
    }, [formattedRates]);

    // -- FLATTEN IF TERM LIFE RATES ARE PRESENT --
    const flattenedTermLifeRows = useMemo(() => {
        if (!hasAnyTermLifeRates) {
            console.log("ReadOnlyEmployerAdminRatesTable: No TLR found, not flattening data.");
            return [];
        }

        console.log("ReadOnlyEmployerAdminRatesTable: TLR found => flattening into separate rows.");

        let allTLRows = [];
        for (const parentRow of formattedRates) {
            const { termLifeRates, ...parentProps } = parentRow;
            if (termLifeRates.length > 0) {
                // For each TLR, create a new row that merges parent + TLR
                termLifeRates.forEach((tlr) => {
                    const childRowId = `${parentRow.rateId}-tlr-${tlr.termLifeRateId || Math.random()}`;
                    const row = {
                        ...parentProps,
                        termLifeRateId: tlr.termLifeRateId,
                        coverageAmount: tlr.coverageAmount,
                        issueAge: tlr.issueAge,
                        coverageRate: tlr.rate,
                        coverageSmoker: tlr.smokingStatus ? 'Yes' : 'No',
                        rateId: childRowId,
                    };
                    allTLRows.push(row);
                });
            }
        }
        console.log("ReadOnlyEmployerAdminRatesTable: Flattened TLR =>", allTLRows);
        return allTLRows;
    }, [hasAnyTermLifeRates, formattedRates]);

    // -- DECIDE WHAT THE "ALL ROWS" FOR THE GRID ARE --
    const allRowsForGrid = useMemo(() => {
        if (hasAnyTermLifeRates) {
            return flattenedTermLifeRows;
        }
        return formattedRates;
    }, [hasAnyTermLifeRates, flattenedTermLifeRows, formattedRates]);

    // -- FILTER THE RATES (NOW ON 'allRowsForGrid') --
    const filteredRates = useMemo(() => {
        const results = allRowsForGrid.filter((row) => {
            const showAllRates = rateType === 'all';
            const matchesRateType =
                row.rateTypeName?.toLowerCase() === rateType.toLowerCase();
            const matchesSearchTerm =
                searchTerm === '' ||
                Object.values(row).some((val) =>
                    val &&
                    val.toString().toLowerCase().includes(searchTerm.toLowerCase())
                );
            return (showAllRates || matchesRateType) && matchesSearchTerm;
        });
        console.log("ReadOnlyEmployerAdminRatesTable: filteredRates =>", results);
        return results;
    }, [allRowsForGrid, rateType, searchTerm]);

    // -- HANDLERS --
    const handleRateTypeChange = (event, newRateType) => {
        if (newRateType !== null) {
            setRateType(newRateType);
            console.log("ReadOnlyEmployerAdminRatesTable: handleRateTypeChange => setRateType to:", newRateType);
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        console.log("ReadOnlyEmployerAdminRatesTable: handleSearchChange => setSearchTerm to:", event.target.value);
    };

    // -- BUILD THE COLUMNS (READ-ONLY) --
    const columns = useMemo(() => {
        if (!rateTypesData || !benefitType || !individualTypesData) {
            console.log("ReadOnlyEmployerAdminRatesTable: columns => Required data is missing, returning empty columns.");
            return [];
        }

        if (!hasAnyTermLifeRates) {
            console.log("ReadOnlyEmployerAdminRatesTable: No TLR => using standard logic from getActiveFieldsForRateType");
            const allColumns = getEmployerAdminRateColumns(
                () => {}, // read-only => no-op
                rateTypesData,
                individualTypesData,
                filteredRates,
                {},
                selectedContributionType,
                showRateCostsPerPayPeriod
            );

            const contributionType = contributionTypesData?.find(
                type => type.contributionTypeId === parseInt(selectedContributionType, 10)
            )?.typeName || '';

            const activeFields = getActiveFieldsForRateType(rateType, benefitType, contributionType);
            const finalColumns = allColumns
                .filter(col => activeFields.includes(col.field))
                .map(col => ({
                    ...col,
                    editable: false,
                    renderCell: (params) => {
                        if (col.type === 'boolean') {
                            return params.value ? 'Yes' : 'No';
                        }
                        return params.value || 'N/A';
                    }
                }));

            console.log("ReadOnlyEmployerAdminRatesTable: columns => Built standard columns array:", finalColumns);
            return finalColumns;
        }

        console.log("ReadOnlyEmployerAdminRatesTable: TLR => building specialized columns for TLR data.");

        // We'll show columns relevant to TLR
        const TLRcolumns = [
            {
                field: 'issueAge',
                headerName: 'Issue Age',
                flex: 1,
                editable: false,
                renderCell: (params) => params.value ?? 'N/A'
            },
            /*{
                field: 'ageBand',
                headerName: 'Age Band',
                flex: 1,
                editable: false,
                renderCell: (params) => params.value ?? 'N/A'
            },*/
            {
                field: 'benefitTypeName',
                headerName: 'Benefit Type',
                flex: 1,
                editable: false,
                renderCell: (params) => params.value ?? 'N/A'
            },
            {
                field: 'eoiMax',
                headerName: 'EOI Max',
                flex: 1,
                editable: false,
                renderCell: (params) => params.value ?? 'N/A'
            },
            {
                field: 'increments',
                headerName: 'Increments',
                flex: 1,
                editable: false,
                renderCell: (params) => params.value ?? 'N/A'
            },
            {
                field: 'min',
                headerName: 'Min',
                flex: 1,
                editable: false,
                renderCell: (params) => params.value ?? 'N/A'
            },
            {
                field: 'max',
                headerName: 'Max',
                flex: 1,
                editable: false,
                renderCell: (params) => params.value ?? 'N/A'
            },
            {
                field: 'coverageAmount',
                headerName: 'Coverage Amount',
                flex: 1,
                editable: false,
                renderCell: (params) => {
                    if (params.value == null) return 'N/A';
                    return `$${Number(params.value).toFixed(2)}`;
                }
            },
            {
                field: 'coverageRate',
                headerName: 'Rate',
                flex: 1,
                editable: false,
                renderCell: (params) => {
                    if (params.value == null) return 'N/A';
                    return `$${Number(params.value).toFixed(2)}`;
                }
            },
            {
                field: 'coverageSmoker',
                headerName: 'Smoking Status',
                flex: 1,
                editable: false,
                renderCell: (params) => params.value ?? 'N/A'
            },
        ];

        console.log("ReadOnlyEmployerAdminRatesTable: columns => Built TLR columns array:", TLRcolumns);
        return TLRcolumns;
    }, [
        rateTypesData,
        benefitType,
        individualTypesData,
        rateType,
        selectedContributionType,
        filteredRates,
        contributionTypesData,
        showRateCostsPerPayPeriod,
        hasAnyTermLifeRates
    ]);

    // -- DETERMINE LOADING & ERROR --
    const isLoading =
        employerAdminRatesDataIsLoading ||
        contributionTypesLoading ||
        benefitTypesLoading ||
        rateTypesLoading ||
        individualTypesLoading ||
        rateCostsPerPayPeriodIsLoading;

    console.log("ReadOnlyEmployerAdminRatesTable: isLoading =>", isLoading);

    if (isLoading) {
        console.log("ReadOnlyEmployerAdminRatesTable: Still loading data, displaying LoadingSpinner.");
        return <LoadingSpinner />;
    }

    if (
        error ||
        contributionTypesError ||
        benefitTypesError ||
        rateTypesError ||
        individualTypesError ||
        rateCostsPerPayPeriodError
    ) {
        console.error(
            "ReadOnlyEmployerAdminRatesTable: Failed to load data =>",
            error ||
            contributionTypesError ||
            benefitTypesError ||
            rateTypesError ||
            individualTypesError ||
            rateCostsPerPayPeriodError
        );
        return <Alert severity="error">Failed to load rates. Please try again later.</Alert>;
    }

    // -- MOBILE & DESKTOP RENDERING --
    const MobileView = () => {
        console.log("ReadOnlyEmployerAdminRatesTable: Rendering MobileView");
        return (
            <Box>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search rates..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    sx={{ mb: 2 }}
                />
                <ToggleButtonGroup
                    value={rateType}
                    exclusive
                    onChange={handleRateTypeChange}
                    aria-label="Rate Type"
                    orientation="vertical"
                    fullWidth
                    sx={{ mb: 2 }}
                >
                    <ToggleButton value="all" aria-label="All Rates">All Rates</ToggleButton>
                    <ToggleButton value="age banded" aria-label="Age Banded Rates">Age Banded</ToggleButton>
                    <ToggleButton value="composite" aria-label="Composite Rates">Composite</ToggleButton>
                </ToggleButtonGroup>
                <List>
                    {filteredRates.map((row, index) => (
                        <React.Fragment key={row.rateId}>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary={`${row.rateTypeName || 'Term Life'} - $${parseFloat(row.rate).toFixed(2)}`}
                                    secondary={
                                        <React.Fragment>
                                            {columns.map(column => {
                                                const val = row[column.field];
                                                const cell = column.renderCell
                                                    ? column.renderCell({ row, value: val })
                                                    : val || 'N/A';
                                                return (
                                                    <Typography
                                                        key={column.field}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                    >
                                                        {`${column.headerName}: ${cell}`}
                                                        <br />
                                                    </Typography>
                                                );
                                            })}
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                            {index < filteredRates.length - 1 && <Divider />}
                        </React.Fragment>
                    ))}
                </List>
            </Box>
        );
    };

    const DesktopView = () => {
        console.log("ReadOnlyEmployerAdminRatesTable: Rendering DesktopView");
        return (
            <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <ToggleButtonGroup
                        value={rateType}
                        exclusive
                        onChange={handleRateTypeChange}
                        aria-label="Rate Type"
                    >
                        <ToggleButton value="all" aria-label="All Rates">All Rates</ToggleButton>
                        <ToggleButton value="age banded" aria-label="Age Banded Rates">Age Banded</ToggleButton>
                        <ToggleButton value="composite" aria-label="Composite Rates">Composite</ToggleButton>
                    </ToggleButtonGroup>
                    <TextField
                        variant="outlined"
                        placeholder="Search rates..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        size="small"
                    />
                </Box>
                <Box sx={{ height: 600, width: '100%' }}>
                    {filteredRates.length > 0 ? (
                        <DataGrid
                            rows={filteredRates}
                            columns={columns}
                            getRowId={(row) => row.rateId}
                            disableSelectionOnClick
                            // Use NoRatesOverlay to avoid the "NoRowsOverlay is not defined" error
                            components={{
                                NoRowsOverlay: NoRatesOverlay,
                            }}
                        />
                    ) : (
                        <Alert severity="info">No rates available for this selection.</Alert>
                    )}
                </Box>
            </Box>
        );
    };

    console.log(
        "ReadOnlyEmployerAdminRatesTable: Rendering final component with benefitType:",
        benefitType,
        "isMobile:",
        isMobile
    );

    return (
        <Paper sx={{ p: 2, width: '100%', overflow: 'hidden' }}>
            <Typography variant="h6" gutterBottom>
                Benefit Type: {benefitType}
            </Typography>
            {isMobile ? <MobileView /> : <DesktopView />}
        </Paper>
    );
};

ReadOnlyEmployerAdminRatesTable.propTypes = {
    plan: PropTypes.shape({
        planId: PropTypes.number.isRequired,
        benefitTypeId: PropTypes.number.isRequired,
    }).isRequired,
    employerCode: PropTypes.string.isRequired,
    showRateCostsPerPayPeriod: PropTypes.bool,
};

export default ReadOnlyEmployerAdminRatesTable;
