import {Alert, Box, Button, Container, Modal, Typography, useTheme} from "@mui/material";
import {formatCurrency} from "../../utilities/formatCurrency";
import React, {useEffect, useState} from "react";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import {
    useGetEmployerByCodeQuery,
    useGetIchraAgeBandedEmployerContributionForEmployeeQuery
} from "../../reducers/enrollmentApiSlice";
import useGetEmployerCode from "../../hooks/useGetEmployerCode";
import {useGetCostPerPayPeriodEbToolKit} from "../../hooks/useGetCostPerPayPeriodEbToolKit";
import {useGetCostPerPayPeriodHsa} from "../../hooks/useGetCostPerPayPeriodHsa";
import {useSelector} from "react-redux";
import LoadingSpinner from "../LoadingSpinner";
import ModalContent from "../Modal/ModalContent";
import {StyledBackdrop} from "../Modal/styled";

const IchraWalletAcknowledge = ({open, setIsIsOpen}) => {

    const { translate } = useCustomTranslation();
    const _ = useTheme();

    const onClose = () => {
      setIsIsOpen(false);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            BackdropComponent={StyledBackdrop}
            aria-labelledby="exceeded-approved-amount-modal"
            aria-describedby="exceeded-approved-amount-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 'min(90%, 400px)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                borderRadius: 2,
            }}>
            <ModalContent>
                <Typography id="exceeded-approved-amount-modal" variant="h6" component="h2" gutterBottom>
                    {translate('Employer Contribution Limit Exceeded')}
                </Typography>

                <Typography component="p" gutterBottom>
                    {translate('Your selection(s) exceed your employer\'s contribution amount. You may still proceed, but please confirm that you’re aware the remaining balance will be deducted from your paycheck every month.')}
                </Typography>
                <Button variant="contained" color="primary"  onClick={onClose} sx={{ mt: 2 }}>{translate('Acknowledge')}</Button>
            </ModalContent>
            </Box>
        </Modal>
    );
}

const IchraWallet = ({userEmail, isOnPlanSelectionPage}) => {
    const { translate } = useCustomTranslation();
    const theme = useTheme();

    const { employerCode, isEmployerCodeLoading, isError: isEmployerCodeError } = useGetEmployerCode({ userEmail });

    const { data: employerData, isLoading: isEmployerDataLoading, isError: isEmployerDataError, error: employerDataError } = useGetEmployerByCodeQuery(
        { employerCode: employerCode, email: userEmail },
        { skip: !employerCode || !userEmail }
    );

    const {
        data: ichraAgeBandedContributionData,
        isLoading: ichraAgeBandedContributionDataIsLoading,
        isFetching: ichraAgeBandedContributionDataIsFetching,
        isError: ichraAgeBandedContributionDataIsError,
        refetch: ichraAgeBandedContributionDataRefetch,
        isUninitialized: ichraAgeBandedContributionIsUninitialized,
    } = useGetIchraAgeBandedEmployerContributionForEmployeeQuery({
        employerCode: employerCode,
        userEmail: userEmail,
    }, {
        skip: !employerCode
    });

    const [isIchraCompany, setIsIchraCompany] = useState(false)
    const [showExceededApprovedLimitModal, setShowExceededApprovedLimitModal] = useState(false);
    const planHsa = useSelector(state => state.hsa.selectedPlanInfo);
    const planIchra = useSelector(state => state.ebToolkit.selectedPlanInfo);
    let monthlyContribution = null;
    let amountSpentPerMonth = null;
    let availableAmount = null;
    let employeeContribution = null;

    const {
        annualCost: annualCostEbToolKit,
        isCostPerPayPeriodDataLoading: isCostPerPayPeriodDataLoadingEbToolKit,
        isCostPerPayPeriodDataError: isCostPerPayPeriodDataErrorEbToolKit
    } = useGetCostPerPayPeriodEbToolKit(userEmail, planIchra ?? null);

    const {
        annualCost: annualCostHsa,
        isCostPerPayPeriodDataLoading: isCostPerPayPeriodDataLoadingHsa,
        isCostPerPayPeriodDataError: isCostPerPayPeriodDataErrorHsa,
    } = useGetCostPerPayPeriodHsa(userEmail, planHsa ?? null);

    const isLoading = isCostPerPayPeriodDataLoadingEbToolKit || ichraAgeBandedContributionDataIsFetching || isCostPerPayPeriodDataLoadingHsa || ichraAgeBandedContributionDataIsLoading || isEmployerCodeLoading;
    const isError = isCostPerPayPeriodDataErrorEbToolKit || isCostPerPayPeriodDataErrorHsa || ichraAgeBandedContributionDataIsError || isEmployerCodeError;

    // Refetch on input change.
    useEffect(() => {
        if (!ichraAgeBandedContributionIsUninitialized) {
            ichraAgeBandedContributionDataRefetch();
        }
        // useGetCostPerPayPeriodEbToolKit and useGetCostPerPayPeriodHsa refetch their own data, so we don't have to do that here.
    }, [ichraAgeBandedContributionData, annualCostEbToolKit, annualCostHsa, planHsa, planIchra, employerCode, userEmail]);

    // Show a warning modal when they spend more than what their employer covers.
    useEffect(() => {
        if (!isLoading && (annualCostHsa != null || annualCostEbToolKit != null) && isOnPlanSelectionPage) {
            let monthlyContribution = ichraAgeBandedContributionData?.data?.monthlyContribution ?? null;
            let amountSpentPerMonth = null;
            if (annualCostHsa != null) {
                amountSpentPerMonth = annualCostHsa / 12;
            } else if(annualCostEbToolKit != null) {
                amountSpentPerMonth = annualCostEbToolKit / 12;
            }
            let employeeContribution = Math.max(0, amountSpentPerMonth - monthlyContribution);

            if (employeeContribution > 0) {
                setShowExceededApprovedLimitModal(true);
            }
        }
    }, [isLoading, annualCostHsa, annualCostEbToolKit, isOnPlanSelectionPage]);

    // Determine if the employer supports Ichra.
    useEffect(() => {
        if (employerData) {
            setIsIchraCompany(employerData?.isIchraCompany ?? false);
        }
    }, [employerData]);

    // Hide the wallet if they do not support Ichra.
    if (!isIchraCompany) {
        return null;
    }

    if(!isLoading && !isError) {
        // Step 1. Get the employer contribution (funded amount).
        monthlyContribution = ichraAgeBandedContributionData?.data?.monthlyContribution ?? null;

        // Step 2. Get the amount spent.
        if (annualCostHsa != null) {
            amountSpentPerMonth = annualCostHsa / 12;
        } else if(annualCostEbToolKit != null) {
            amountSpentPerMonth = annualCostEbToolKit / 12;
        }

        // Step 3. Calculate the available amount.
        availableAmount = Math.max(0, monthlyContribution - amountSpentPerMonth);

        // Step 4. Calculate the employee contribution spent.
        employeeContribution = Math.max(0, amountSpentPerMonth - monthlyContribution);
    }

    return (<Container component="main" maxWidth="sm" sx={{ p: 2 }}>
        <Box sx={{ mt: 4, p: 3, backgroundColor: theme.palette.background.paper, borderRadius: '8px', boxShadow: theme.shadows[3] }}>
            <Typography component="h1" variant="h5" sx={{ mb: 2}}>
                {translate('Monthly Amounts (ICHRA)')}
            </Typography>

            {isLoading ? <LoadingSpinner /> : null}
            {isError ? <Alert severity="error">{translate('Failed to load the required data.')}</Alert> : null }

            {!isLoading && !isError ? (<>
            <Typography component="h6" variant="h6" sx={{ fontWeight: 500 }}>{translate('Funded Amount')}</Typography>
            <Typography sx={{ mb: 0.5 }}>{formatCurrency(monthlyContribution)}</Typography>

            <Typography component="h6" variant="h6" sx={{ fontWeight: 500 }}>{translate('Amount Spent')}</Typography>
            <Typography sx={{ mb: 0.5 }}>{formatCurrency(amountSpentPerMonth)}</Typography>

            <Typography component="h6" variant="h6" sx={{ fontWeight: 500 }}>{translate('Available Amount')}</Typography>
            <Typography sx={{ mb: 0.5 }}>{formatCurrency(availableAmount)}</Typography>

            <Typography component="h6" variant="h6" sx={{ fontWeight: 500 }}>{translate('Employee Contribution')}</Typography>
            <Typography sx={{ mb: 0.5 }}>{formatCurrency(employeeContribution)}</Typography>

            <IchraWalletAcknowledge open={showExceededApprovedLimitModal} setIsIsOpen={setShowExceededApprovedLimitModal} />
            </>)
            : null}
        </Box>
    </Container>);
}

export default IchraWallet;