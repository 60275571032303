import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    useTheme,
    Button,
    Paper,
} from '@mui/material';
import ThemeToggle from "./ThemeToggle";
import LanguageSwitcher from "./LanguageSwitcher";
import {
    useGetAccountDetailsQuery,
    useGetPreferencesByEmailQuery
} from "../reducers/enrollmentApiSlice";
import AuthTokenService from '../services/AuthTokenService';
import useCustomTranslation from "../hooks/useCustomTranslation";
import {AccountNotificationSettings} from "./AccountNotificationSettings";
import {Policy} from "../types/policy";
import PolicyBasedAccess from "./PolicyBasedAccess";

const AccountSettings = () => {
    const theme = useTheme();  // Access the current theme
    const { user } = AuthTokenService.getAuthInfo();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const { translate } = useCustomTranslation();
    const [preferences, setPreferences] = useState(null);

    const {
        data: accountDetails,
        isLoading: accountDetailsIsLoading,
        isError: accountDetailsIsError,
        refetch: refetchAccountDetails,
    } = useGetAccountDetailsQuery({ email: user });

    const {data: accountPreferencesData, isLoading: accountPreferencesIsLoading, isError: accountPreferencesIsError, refetch: accountPreferencesRefetch} = useGetPreferencesByEmailQuery({accountEmail: user});

    const isLoading = accountPreferencesIsLoading || accountDetailsIsLoading;
    const isError = accountPreferencesIsError || accountDetailsIsError;

    useEffect(() => {
        if (accountDetails && !accountDetailsIsLoading && !accountDetailsIsError) {
            setFirstName(accountDetails.firstName);
            setLastName(accountDetails.lastName);
        }
    }, [accountDetails, accountDetailsIsLoading, accountDetailsIsError]);

    useEffect(() => {
        if (accountPreferencesData != null && !accountPreferencesIsLoading && !accountPreferencesIsError) {
            setPreferences(accountPreferencesData ?? null);
        }
    }, [accountPreferencesData, accountPreferencesIsLoading, accountPreferencesIsError]);

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh" aria-live="polite">
                <CircularProgress aria-label={translate('Loading account details...')} />
            </Box>
        );
    }

    if (isError) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Typography color={theme.palette.error.main} role="alert">
                    {translate('Error loading account details. Please try again later.')}
                </Typography>
                <Button onClick={refetchAccountDetails} variant="outlined" sx={{ mt: 2 }}>
                    {translate('Retry')}
                </Button>
            </Box>
        );
    }

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            sx={{
                backgroundColor: theme.palette.background.default,
                padding: theme.spacing(4),
            }}
            aria-labelledby="account-settings-heading"
        >
            <Box
                component="section"
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: theme.spacing(4),
                    mt: theme.spacing(4),
                    mb: theme.spacing(4),
                    width: '100%',
                    maxWidth: theme.breakpoints.values.sm,
                    color: theme.palette.text.primary, // Ensures the text color adapts to the theme
                }}
                aria-labelledby="account-settings-heading"
            >
                <Typography
                    id="account-settings-heading"
                    variant="h4"
                    component="h2"
                    sx={{
                        width: '100%',
                        textAlign: 'center',
                    }}
                >
                    {translate('Account Settings')}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        gap: theme.spacing(2),
                        width: '100%',
                    }}
                >
                    <Typography sx={{ display: 'flex', justifyContent: 'center', gap: theme.spacing(2) }} variant='h5'>
                        <Typography component="span" fontWeight='normal'>{translate('Name')}:</Typography> {firstName} {lastName}
                    </Typography>
                    <Typography sx={{ display: 'flex', justifyContent: 'center', gap: theme.spacing(2) }} variant='h5'>
                        <Typography component="span" fontWeight='normal'>{translate('Email')}:</Typography> {user}
                    </Typography>
                </Box>
                <Paper
                    elevation={3}
                    sx={{
                        padding: theme.spacing(2),
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        gap: theme.spacing(2),
                        backgroundColor: theme.palette.background.paper,
                    }}
                >
                    <ThemeToggle />
                    <LanguageSwitcher />
                </Paper>

                <PolicyBasedAccess
                    policy={Policy.GlobalAdminAgentEmployerAdminBroker}
                >
                    <AccountNotificationSettings email={user} preferences={preferences} refetch={accountPreferencesRefetch} />
                </PolicyBasedAccess>

            </Box>
        </Box>
    );
}

export default AccountSettings;
