// Define the Piedmont payment endpoints
export const piedmontPaymentEndpoints = (builder) => ({
    // Fetch employee payment information
    getEmployeePaymentInfo: builder.query({
        query: (request) => ({
            url: `/PiedmontPayment/GetEmployeePaymentInfo?year=${request.year}&email=${encodeURIComponent(request.email)}`,
            method: 'GET'
        }),
        transformResponse: (response) => {
            // Handle the transformation if needed
            if (!response) {
                return null; // No content or invalid response
            }
            return response;
        },
    }),
});
