import React from "react";
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import ForgotPassword from './pages/ForgotPassword';
import AuthRouteWrapper from './components/AuthRouteWrapper';
// Components
import HomePage from "./pages/HomePage";
import PdfUploader from "./components/PdfUpload/PdfUploader";
import MultiPdfUploader from "./components/PdfUpload/MultiPdfUploader";

// Pages
import Login from "./components/LoginPage/Login";
import Register from "./components/RegisterPage/Register";
import ContactUs from "./pages/ContactUs";

import NewAccount from "./components/NewAccount/NewAccount";
import RecoverMyEmail from "./pages/RecoverMyEmail";
import CensusUpload from "./components/CensusUpload/CensusUpload";
import LoadingBar from "./components/LoadingBar";
import {Policy} from "./types/policy";
import PlanSearch from "./components/PlanSelection/PlanSearch";
import AccountInformation from "./components/AccountInformation/AccountInformation";
import DependentPage from "./components/AddDependents/DependentPage";
import AddAddressPage from "./components/AddAddress/AddAddressPage";
import PlanSelectionForm from "./components/PlanSelection";
import EnrollmentMedicalQuestionsPage from "./components/EnrollmentMedicalQuestionsPage/EnrollmentMedicalQuestionsPage";
import AgreeAndSignPage from "./components/AgreeAndSign/AgreeAndSignPage";
import ReviewPage from "./components/ReviewBenefitPage/ReviewPage";
import BuildPlansPage from "./components/BuildPlans/BuildPlansPage";
import CreateCarrierPage from "./components/Carrier/CreateCarrierPage";
import EnrollmentPage from "./components/BenefitEnrollment/EnrollmentPage";
import ResendConfirmationAndConfirm from "./components/ResendConfirmationAndConfirm";
import AccountSettings from "./components/AccountSettings";
import PlanAssociationWithEmployer from "./components/Employer/PlanAssociationWithEmployer";
import EmployerAdministratorPortal from "./components/Employer/EmployerAdministratorPortal";
import ManageEmployees from "./components/Employees/ManageEmployees";
import EditAccountInformation from "./components/Employees/EditAccountInformation";
import EditEmployerInfo from "./components/Employer/EditEmployerInfo";
import CreateEmployer from "./components/Employer/CreateEmployer";
import BrokerPortal from "./components/Broker/BrokerPortal";
import AdminEnrollmentPage from "./components/AdminEnroll/AdminEnrollmentPage";
import HSAEnrollment from "./components/ICHRA/HSA/HSAEnrollment";
import CinciLifePDFViewer from "./components/LifeInsurance/CinciLifePDFViewer";
import EbIchraEnrollment from "./components/ICHRA/Ebtoolkit/EbIchraEnrollment";
import AgencyPortal from "./components/Agency/AgencyPortal";
import CreateAgency from "./components/Agency/CreateAgency";
import AgencyDetails from "./components/Agency/AgencyDetails";
import AssociateEmployersWithAgency from "./components/Agency/AssociateEmployersWithAgency";
import CreateAgencyPlans from "./components/Agency/CreateAgencyPlans";
import BenefitEnrollmentComponent from "./components/BenefitEnrollment/BenefitEnrollmentComponent";
import ReportingPage from "./components/Reporting/ReportingPage";
import PiedmontIntegration from "./components/Piedmont/PiedmontIntegration";



// We might want to consider doing dynamic imports with React lazy(), so that we can do code-splitting per route.

const MainContent = () => {
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

    return (
        <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
            <Routes>
                <Route path="/" element={!isAuthenticated ? <Login /> : <Navigate replace to="/home"/>}/>
                <Route path="/login" element={!isAuthenticated ? <Login /> : <Navigate replace to="/home"/>}/>
                <Route path="/register" element={!isAuthenticated ? <Register /> : <Navigate replace to="/home"/>}/>
                <Route path="/forgot-password" element={!isAuthenticated ? <ForgotPassword /> : <Navigate replace to="/home"/>}/>
                <Route path="/recover-my-email" element={!isAuthenticated ? <RecoverMyEmail /> : <Navigate replace to="/home"/>}/>


                {/* Authenticated Routes START*/}
                <Route path="/benefit-enrollment/:email" element={<AuthRouteWrapper policy={Policy.AnyClaim}><BenefitEnrollmentComponent /></AuthRouteWrapper>}/>

                <Route path="/home" element={<AuthRouteWrapper policy={Policy.AnyClaim}><HomePage/></AuthRouteWrapper>}/>
                <Route path="/plan-search" element={<AuthRouteWrapper policy={Policy.AnyClaim}><PlanSearch/></AuthRouteWrapper>}/>
                <Route path="/account-settings" element={<AuthRouteWrapper policy={Policy.AnyClaim}><AccountSettings/></AuthRouteWrapper>}/>
                <Route path="/hsa" element={<AuthRouteWrapper><HSAEnrollment/></AuthRouteWrapper>}/>
                <Route path="/ichra" element={<AuthRouteWrapper><EbIchraEnrollment/></AuthRouteWrapper>}/>


                {/* GLOBAL ADMIN ROUTES */}
                <Route path="/create-agency" element={<AuthRouteWrapper policy={Policy.GlobalAdmin}><CreateAgency /></AuthRouteWrapper>} />
                <Route path="/associate-agency" element={<AuthRouteWrapper policy={Policy.GlobalAdmin}><AssociateEmployersWithAgency /></AuthRouteWrapper>} />
                <Route path="/associate-plans-agency" element={<AuthRouteWrapper policy={Policy.GlobalAdmin}><CreateAgencyPlans /></AuthRouteWrapper>} />

                {/* GLOBAL ADMIN  + Agency ROUTES */}
                <Route path="/agency-portal" element={<AuthRouteWrapper policy={Policy.AgencyOrGlobalAdmin}><AgencyPortal /></AuthRouteWrapper>} />
                <Route path="/agency-details" element={<AuthRouteWrapper policy={Policy.AgencyOrGlobalAdmin}><AgencyDetails /></AuthRouteWrapper>} />
                {/* GLOBAL ADMIN  + Agency + Broker ROUTES */}

                <Route path="/admin/census-upload" element={<AuthRouteWrapper policy={Policy.GlobalAdminAgencyBroker}><CensusUpload /></AuthRouteWrapper>} />
                <Route path="/build-plan" element={<AuthRouteWrapper policy={Policy.GlobalAdminAgencyBroker}><BuildPlansPage /></AuthRouteWrapper>} />
                <Route path="/create-carrier" element={<AuthRouteWrapper policy={Policy.GlobalAdminAgencyBroker}><CreateCarrierPage /></AuthRouteWrapper>} />
                <Route path="/create-employer" element={<AuthRouteWrapper policy={Policy.GlobalAdminAgencyBroker}><CreateEmployer /></AuthRouteWrapper>} />
                <Route path="/broker-portal" element={<AuthRouteWrapper policy={Policy.GlobalAdminAgencyBroker}><BrokerPortal /></AuthRouteWrapper>} />


                {/*Anyone but users*/}
                <Route path="/employer-plan-association" element={<AuthRouteWrapper policy={Policy.GlobalAdminAgentEmployerAdminBroker}><PlanAssociationWithEmployer/></AuthRouteWrapper>} />
                <Route path="/admin/employer-administrator-portal" element={<AuthRouteWrapper policy={Policy.GlobalAdminAgentEmployerAdminBroker}><EmployerAdministratorPortal/></AuthRouteWrapper>} />
                <Route path="/manage-employees" element={<AuthRouteWrapper policy={Policy.GlobalAdminAgentEmployerAdminBroker}><ManageEmployees/></AuthRouteWrapper>} />
                <Route path="/edit-account-information/:email" element={<AuthRouteWrapper policy={Policy.GlobalAdminAgentEmployerAdminBroker}><AdminEnrollmentPage/></AuthRouteWrapper>} />
                <Route path="/edit-employer-information" element={<AuthRouteWrapper policy={Policy.GlobalAdminAgentEmployerAdminBroker}><EditEmployerInfo/></AuthRouteWrapper>} />
                <Route path="/reporting" element={<AuthRouteWrapper policy={Policy.GlobalAdminAgentEmployerAdminBroker}><
                    ReportingPage/></AuthRouteWrapper>} />


                {/* User Routes START */}
                <Route path="/benefit-enrollment" element={<AuthRouteWrapper><EnrollmentPage /></AuthRouteWrapper>}/>

                <Route path="/account-information" element={<AuthRouteWrapper><AccountInformation /></AuthRouteWrapper>}/>
                <Route path="/new-account" element={<AuthRouteWrapper><NewAccount /></AuthRouteWrapper>}/>
                <Route path="/add-dependent" element={<AuthRouteWrapper><DependentPage/></AuthRouteWrapper>}/>
                <Route path="/add-address" element={<AuthRouteWrapper><AddAddressPage/></AuthRouteWrapper>}/>
                <Route path="/select-plan" element={<AuthRouteWrapper><PlanSelectionForm/></AuthRouteWrapper>}/>
                <Route path="/upload-medical-docs" element={<AuthRouteWrapper><EnrollmentMedicalQuestionsPage/></AuthRouteWrapper>}/>
                <Route path="/agree-and-sign-insurance-enrollment" element={<AuthRouteWrapper><AgreeAndSignPage/></AuthRouteWrapper>}/>
                <Route path="/review-page" element={<AuthRouteWrapper><ReviewPage/></AuthRouteWrapper>}/>

                {/* User Routes END */}

                {/* Authenticated Routes END */}

                {/* Unauthenticated Routes*/}
                <Route path="/contact-us" element={<ContactUs/>}/>
                {/*<Route path="/pdf-uploader" element={<PdfUploader/>}/>*/}
                <Route path="/pdf-viewer" element={<CinciLifePDFViewer/>}/>
                <Route path="/confirm-account" element={<ResendConfirmationAndConfirm />} />


                {/* TESTING DO NOT PUSH */}
                <Route
                    path="/pays"
                    element={<PiedmontIntegration year={2024} email="sam-test2@gowellbenefits.com" />}
                />
                {/* Redirect unauthenticated users attempting to access unknown routes to the login page */}
                <Route path="*" element={<Navigate replace to="/login"/>}/>


            </Routes>
        </div>
    );
};

export default MainContent;
