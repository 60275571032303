import React from 'react';
import EmployerCheckboxList from '../Employer/EmployerCheckboxList';

const EmployerSelector = ({ email, onEmployerSelect }) => {
    return (
        <EmployerCheckboxList email={email} onEmployerSelect={onEmployerSelect} />
    );
};

export default EmployerSelector;
