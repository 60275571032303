import {
    Alert,
    Box,
    Button,
    Collapse,
    List,
    ListItem,
    Snackbar,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import React, {useState} from "react";
import useAlert from "../../hooks/useAlert";
import {useArchiveDisclosureMutation} from "../../reducers/enrollmentApiSlice";
import LoadingSpinner from "../LoadingSpinner";
import dayjs from "dayjs";

const DisclosureList = ({ allDisclosures, refetch, isRefetching, employerCode, userEmail }) => {
    const theme = useTheme();
    const { translate } = useCustomTranslation();
    const [expandedIds, setExpandedIds] = useState({});
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {alert, setAlert, handleCloseAlert} = useAlert();
    const [archiveDisclosure, { isLoading: isUpdating, isError: isUpdateError, error: updateError }] = useArchiveDisclosureMutation();

    const toggleExpand = (id) => {
        setExpandedIds((prevState) => ({
            ...prevState,
            // Toggle the expanded state.
            [id]: !prevState[id],
        }));
    };

    const handleArchiveDisclosure = async (disclosureId) => {
        try {
            await archiveDisclosure({
                disclosureId: disclosureId,
                employerCode: employerCode,
                changedBy: userEmail
            }).unwrap();
            setAlert({
                open: true,
                severity: 'success',
                message: translate('Archived!')
            });
            await refetch();
        } catch(error) {
            console.error('Failed to archive the disclosure:', error);
            setAlert({
                open: true,
                severity: 'error',
                message: translate('Failed to archive the disclosure.')
            });
        }
    };

    if (isRefetching) {
        return (<LoadingSpinner />);
    }

    const sortedDisclosures = structuredClone(allDisclosures?.data ?? []).sort( (disclosure1, disclosure2) => {
        return dayjs(disclosure1.updatedAt).isBefore(dayjs(disclosure2.updatedAt));
    });

    return (
        <List>
            <Snackbar
                open={alert.open}
                autoHideDuration={6000}
                onClose={() => {setAlert({ open: false, severity: '', message: '' });}}
            >
                <Alert onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>
                    {translate(alert.message)}
                </Alert>
            </Snackbar>

            {sortedDisclosures.map((item, index) => {
                const maxTextLength = 50;
                const maxLength = maxTextLength - '...'.length;
                const isExpanded = expandedIds[item.disclosureId];

                let title = item.disclosureText;
                if (item.disclosureText.length > maxLength) {
                    title = title.substring(0, maxLength) + '...';
                }

                return (
                    <ListItem key={item.disclosureId} divider sx={{
                        display: 'flex',
                        marginBottom: 0,
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        flexDirection: isMobile ? 'column-reverse': 'row',
                        rowGap: 2
                    }}>
                        <Box sx={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="body1" gutterBottom>
                                {title}
                            </Typography>

                            <Collapse
                                in={isExpanded}
                                timeout="auto"
                                unmountOnExit
                                sx={{
                                    overflowY: 'scroll',
                                    maxHeight: '500px',
                                    background: isExpanded ? theme.palette.background.default : 'inherit',
                                    padding: isExpanded ? '2rem' : 0,
                                    minWidth: '200px',
                                    mt: 2,
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    sx={{
                                        whiteSpace: 'pre-wrap', // Retain whitespace and line breaks
                                        wordBreak: 'break-word', // Ensure long words break correctly
                                    }}
                                >
                                    {item.disclosureText}
                                </Typography>
                            </Collapse>

                            {/* Expand/Collapse Button */}
                            {item.disclosureText.length > maxLength && (
                                <Button
                                    size="small"
                                    onClick={() => toggleExpand(item.disclosureId)}
                                    sx={{ textTransform: 'none', padding: 0 }}
                                >
                                    {isExpanded ? translate('Show Less') : translate('Show More')}
                                </Button>
                            )}
                        </Box>

                        {/* Copy Button */}
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', rowGap: '2rem' }}>
                            <Button
                                size="small"
                                variant="outlined"
                                sx={{ marginLeft: 1 }}
                                onClick={() => navigator.clipboard.writeText(item.disclosureText)}
                            >
                                {translate('Copy to Clipboard')}
                            </Button>
                            {index === 0 && item.isActive === true ? (<Button
                                size="small"
                                variant="outlined"
                                sx={{ marginLeft: 1 }}
                                onClick={() => handleArchiveDisclosure(item.disclosureId)}
                            >
                                {isUpdating ? translate('Archiving') : translate('Archive')}
                            </Button>) : null}
                        </Box>
                    </ListItem>
                );
            })}
        </List>
    );
};

export default DisclosureList;