export const getDependentIdsFromDependentsCoverage = (dependentsCoverage) => {
    let selectedDependentsTemp = [];
    const dependentIds = Object.keys(dependentsCoverage);
    for(let i = 0; i < dependentIds.length; ++i) {
        if (dependentsCoverage[dependentIds[i]]) {
            selectedDependentsTemp.push('' + dependentIds[i]);
        }
    }

    return selectedDependentsTemp;
}