import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Container,
    Paper,
    Box,
    Divider,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    useTheme,
    useMediaQuery,
    CircularProgress,
    Link,
    Typography,
    Button,
    ListItemText,
} from '@mui/material';
import { completeReviewPage, updateDependentsData } from '../../reducers/benefitEnrollmentSlice';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import { formatEnrollment } from '../../reducers/formatEnrollmentSlice';
import {
    useGetGendersQuery,
    useGetMaritalStatusesQuery,
    useGetAllRateTypesQuery,
    useGetBenefitTypesQuery,
    useGetAllRelationshipsQuery,
    useGetAddressTypesQuery,
    useGetAllRegionsQuery,
    useGetAllEmployeeStatusesQuery,
    useGetContactByEmailQuery,
    useGetDependentsByAccountEmailQuery,
    useAddCoverageMutation,
} from "../../reducers/enrollmentApiSlice";
import AuthTokenService from "../../services/AuthTokenService";
import Header from './Header';
import PersonalInformation from './PersonalInformation';
import DependentsInformation from './DependentsInformation';
import AddressInformation from './AddressInformation';
import SelectedPlans from './SelectedPlans';
import CompletionMessage from "./CompletionMessage";
import NavigationButtons from "./NavigationButtons";
import SelectedPlansHSA from "../ICHRA/HSA/SelectedPlansHSA";
import SelectedPlansIchra from "../ICHRA/Ebtoolkit/SelectedPlansIchra";
import SelectedPlanLife from "./SelectedPlanLife";
import WarningModalForNoDependents from "./WarningModalForNoDependents";
import useLocalSelectedPlansData from "../../hooks/useLocalSelectedPlansData";

const steps = [
    'Personal Information',
    'Dependents Information',
    'Address Information',
    'Selected Plans',
];

const ReviewPage = ({
                        onNavigateToAccountPage,
                        onNavigateToDependentPage,
                        onNavigateToAddressPage,
                        onNavigateToPlanSelectionPage,
                        onNext,
                    }) => {
    const dispatch = useDispatch();
    const { translate } = useCustomTranslation();
    const { user: userEmail } = AuthTokenService.getAuthInfo();
    const selectedPlans = useSelector((state) => state.selectedPlan);
    const reviewPage = useSelector((state) => state.benefitEnrollment.reviewPage);
    const formData = useSelector((state) => state.benefitEnrollment.formData);
    const addressesData = useSelector((state) => state.benefitEnrollment.addressesData);
    const [dependentsCoverage, setDependentsCoverage] = useState({});
    const [activeStep, setActiveStep] = useState(0);
    const [localDependentCoverages, setLocalDependentCoverages] = useState({});
    const [shouldShowWarningModalForNoDependents, setShouldShowWarningModalForNoDependents] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Fetch data using the provided hooks
    const { data: genders, isLoading: isLoadingGenders } = useGetGendersQuery();
    const { data: maritalStatuses, isLoading: isLoadingMaritalStatuses } = useGetMaritalStatusesQuery();
    const { data: rateTypes, isLoading: isLoadingRateTypes } = useGetAllRateTypesQuery();
    const { data: benefitTypes, isLoading: isLoadingBenefitTypes } = useGetBenefitTypesQuery();
    const { data: relationships, isLoading: isLoadingRelationships } = useGetAllRelationshipsQuery();
    const { data: addressTypes, isLoading: isLoadingAddressTypes } = useGetAddressTypesQuery();
    const { data: regions, isLoading: isLoadingRegions } = useGetAllRegionsQuery();
    const { data: employeeStatuses, isLoading: isLoadingEmployeeStatuses } = useGetAllEmployeeStatusesQuery();
    const { data: contactDetails, isLoading: isLoadingContactDetails } = useGetContactByEmailQuery({ email: userEmail });
    const { data: dependentsData, isLoading: isLoadingDependents } = useGetDependentsByAccountEmailQuery({ email: userEmail });

    // For additional plan types
    const { selectedPlanInfo: selectedPlanHsa, selectedDependents: selectedDependentsHsa, waive: waiveHsa } =
        useSelector((state) => state.hsa);
    const { selectedPlanInfo: selectedPlanIchra, selectedDependents: selectedDependentsIchra, waive: waiveIchra } =
        useSelector((state) => state.ebToolkit);
    const currentlySelectedPlansWithDependents = useSelector((state) => state.planCoverage.selectedPlansWithDependents);

    // RTK Query: Add Coverage Mutation
    const [addCoverage, { isLoading: isSubmitting }] = useAddCoverageMutation();

    // Local hook for storing any plan-based user selections, including coverage amounts
    const localSelectedPlans = useLocalSelectedPlansData({
        selectedPlans: selectedPlans,
        dependentsData,
        currentlySelectedPlansWithDependents,
    });

    // Combine loading states
    const isLoading =
        isLoadingGenders ||
        isLoadingMaritalStatuses ||
        isLoadingRateTypes ||
        isLoadingBenefitTypes ||
        isLoadingRelationships ||
        isLoadingAddressTypes ||
        isLoadingRegions ||
        isLoadingEmployeeStatuses ||
        isLoadingContactDetails ||
        isLoadingDependents;

    useEffect(() => {
        if (dependentsData) {
            const processedDependents = dependentsData.map((dependent) => ({
                firstName: dependent.person.firstName,
                lastName: dependent.person.lastName,
                dob: dependent.person.dateOfBirth,
                gender: dependent.person.genderId,
                ssn: dependent.person.socialSecurityNumber,
                relationship: {
                    relationshipId: dependent.relationship.relationshipId,
                    relationshipName: dependent.relationship.relationshipName,
                },
                isActive: dependent.isActive,
            }));
            dispatch(updateDependentsData(processedDependents));
        }
    }, [dependentsData, dispatch]);

    const handleNext = () => {
        setActiveStep((prevStep) => prevStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const handleSubmit = async () => {
        console.log('Submitting enrollment with data:', {
            selectedPlans,
            formData,
            dependentsData,
            dependentsCoverage,
        });

        if (getPlansWithNoDependentsSelected().length > 0) {
            setShouldShowWarningModalForNoDependents(true);
            return;
        }

        try {
            // Filter out plans where coverage amount is not set
            const plansWithCoverage = selectedPlans.filter((plan) => {
                const coverageAmount =
                    localSelectedPlans.coverageAmounts[plan.planId] !== undefined
                        ? localSelectedPlans.coverageAmounts[plan.planId]
                        : plan.coverageAmount; // fallback if needed

                return coverageAmount != null && coverageAmount > 0.0 && plan.decision !== 'waive';
            });

            // Send coverage info only for plans with a set coverage amount
            const results = await Promise.all(
                plansWithCoverage.map(async (plan) => {
                    const coverageAmount =
                        localSelectedPlans.coverageAmounts[plan.planId] !== undefined
                            ? localSelectedPlans.coverageAmounts[plan.planId]
                            : plan.coverageAmount;

                    return await addCoverage({
                        email: userEmail,
                        coverageAmount,
                        planId: plan.planId,
                    }).unwrap();
                })
            );

            console.log('Coverage submissions successful:', results);
            finalizeSubmit();
        } catch (error) {
            console.error('Failed to submit coverage:', error);
        }
    };

    const finalizeSubmit = () => {
        dispatch(
            formatEnrollment({
                selectedPlans,
                formData,
                dependentsData,
                dependentsCoverage,
            })
        );
        dispatch(completeReviewPage());
        onNext();
    };

    const getPlansWithNoDependentsSelected = () => {
        let plansWithoutDependents = [];

        if (!waiveHsa.shouldWaive && selectedPlanHsa != null && selectedDependentsHsa.length === 0) {
            plansWithoutDependents.push(
                <ListItemText>
                    {(selectedPlanHsa?.planDescpt ?? '') + ' (ICHRA)'}
                </ListItemText>
            );
        }

        if (!waiveIchra.shouldWaive && selectedPlanIchra != null && selectedDependentsIchra.length === 0) {
            plansWithoutDependents.push(
                <ListItemText>
                    {(selectedPlanIchra?.planName ?? '') + ' (ICHRA)'}
                </ListItemText>
            );
        }

        let localPlansWithoutDependents = selectedPlans.filter((plan) => {
            const dependents = Object.values(localDependentCoverages[plan.planId] ?? []);
            let hasAtLeastOneDependentCovered = false;
            if (dependents.length > 0 && dependents.some((dependent) => dependent.isCovered === true)) {
                hasAtLeastOneDependentCovered = true;
            }
            return (
                plan.decision !== 'waive' &&
                !(plan.planId in localDependentCoverages) &&
                !hasAtLeastOneDependentCovered
            );
        });

        for (let i = 0; i < localPlansWithoutDependents.length; ++i) {
            plansWithoutDependents.push(
                <ListItemText>
                    {localPlansWithoutDependents[i]?.productName ?? ''}
                </ListItemText>
            );
        }

        return plansWithoutDependents;
    };

    if (isLoading || isSubmitting) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    const updatedFormData = {
        ...formData,
        phoneNumber: contactDetails?.phoneNumber || '',
    };

    const trackLocalDependentChangesPerPlan = (dependentCoverages) => {
        setLocalDependentCoverages((prevState) => ({ ...prevState, ...dependentCoverages }));
    };

    return (
        <Container component="main" maxWidth="lg" sx={{ py: 2 }}>
            <Paper elevation={3} sx={{ p: { xs: 2, md: 4 }, mt: { xs: 2, md: 4 }, mb: 4 }}>
                <Header isMobile={isMobile} />
                <Divider sx={{ my: isMobile ? 2 : 3 }} />

                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{translate(label)}</StepLabel>
                            <StepContent>
                                <Box sx={{ mt: 2, mb: 1 }}>
                                    {index === 0 && (
                                        <PersonalInformation
                                            onNavigateToAccountPage={onNavigateToAccountPage}
                                            formData={updatedFormData}
                                            genders={genders}
                                            maritalStatuses={maritalStatuses}
                                            employeeStatuses={employeeStatuses}
                                        />
                                    )}
                                    {index === 1 && (
                                        <DependentsInformation
                                            dependentsData={dependentsData}
                                            genders={genders}
                                            relationshipTypes={relationships}
                                            onNavigateToDependentPage={onNavigateToDependentPage}
                                        />
                                    )}
                                    {index === 2 && (
                                        <AddressInformation
                                            addressesData={addressesData}
                                            addressTypes={addressTypes}
                                            regions={regions}
                                            onNavigateToAddressPage={onNavigateToAddressPage}
                                        />
                                    )}
                                    {index === 3 && (
                                        <>
                                            <Typography
                                                id="selected-plans-title"
                                                component="h2"
                                                variant="h6"
                                                gutterBottom
                                            >
                                                {translate('Selected Plans')}
                                            </Typography>

                                            <SelectedPlanLife
                                                selectedPlans={selectedPlans}
                                                dependentsData={dependentsData}
                                                dependentsCoverage={dependentsCoverage}
                                                onDependentsChange={trackLocalDependentChangesPerPlan}
                                                localSelectedPlanData={localSelectedPlans}
                                            />
                                            <SelectedPlans
                                                selectedPlans={selectedPlans}
                                                dependentsData={dependentsData}
                                                dependentsCoverage={dependentsCoverage}
                                                onDependentsChange={trackLocalDependentChangesPerPlan}
                                                localSelectedPlanData={localSelectedPlans}
                                            />
                                            <SelectedPlansHSA
                                                userEmail={userEmail}
                                                selectedPlan={selectedPlanHsa}
                                                selectedDependents={selectedDependentsHsa}
                                                waive={waiveHsa}
                                            />
                                            <SelectedPlansIchra
                                                userEmail={userEmail}
                                                selectedPlan={selectedPlanIchra}
                                                selectedDependents={selectedDependentsIchra}
                                                waive={waiveIchra}
                                            />

                                            <Box sx={{ mt: 2, textAlign: 'right' }}>
                                                <Link
                                                    component="button"
                                                    variant="body2"
                                                    onClick={onNavigateToPlanSelectionPage}
                                                    sx={{ textDecoration: 'none' }}
                                                    aria-label={translate('Edit Selected Plans')}
                                                >
                                                    {translate('Edit Selected Plans')}
                                                </Link>
                                            </Box>

                                            <WarningModalForNoDependents
                                                setShouldShowWarningModalForNoDependents={
                                                    setShouldShowWarningModalForNoDependents
                                                }
                                                shouldShowWarningModalForNoDependents={
                                                    shouldShowWarningModalForNoDependents
                                                }
                                                finalizeSubmit={finalizeSubmit}
                                                getPlansWithNoDependentsSelected={
                                                    getPlansWithNoDependentsSelected
                                                }
                                            />
                                        </>
                                    )}
                                </Box>
                                <NavigationButtons
                                    index={index}
                                    stepsLength={steps.length}
                                    onBack={handleBack}
                                    onNext={
                                        index === steps.length - 1
                                            ? handleSubmit
                                            : handleNext
                                    }
                                />
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                <CompletionMessage reviewPage={reviewPage} />
            </Paper>
        </Container>
    );
};

export default ReviewPage;