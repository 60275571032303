import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';

const YearSelector = ({ selectedYear, onYearChange }) => {
    const currentYear = new Date().getFullYear();

    return (
        <Box
            sx={{
                mt: 2,
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <FormControl
                sx={{ minWidth: 200 }}
                variant="outlined"
                aria-labelledby="year-select-label"
            >
                <InputLabel id="year-select-label">Select Year</InputLabel>
                <Select
                    labelId="year-select-label"
                    value={selectedYear}
                    onChange={(e) => onYearChange(e.target.value)}
                    label="Select Year"
                    aria-describedby="year-select-helper-text"
                >
                    {[...Array(11)].map((_, index) => (
                        <MenuItem key={index} value={currentYear - index}>
                            {currentYear - index}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default YearSelector;
